import React from "react";
import Header from "../Header";
// import {BTGIsRegisteredUser} from "@contracts/BuildTowerGame";
// import {useMoralis} from "react-moralis";
// import Preloader from "@components/Preloader";
// import Register from "@pages/Register";
// import {useRecoilState} from "recoil";
// import {isRegisteredAtom} from "@stores/account";
import Sidebar, {NavPages} from "@components/Sidebar";
import Footer from "@components/Footer";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function AccountPage() {
  const {t} = useTranslation();
  const {page} = useParams();
  const currentPage = NavPages.find((p) => p.path === `/${page || ''}`);
  const isTestMode = window.location.href.includes("testnet") || process.env.REACT_APP_IS_TEST_ENV === "true";

  return (
    <div className="page-wrapper">
      {isTestMode && (
        <p className="h4 position-fixed bg-danger mb-25 w-100 text-white text-center">{t("warning.test-mode")}</p>
      )}
      <Sidebar/>
      <div className="page" id={currentPage?.id || 'not-found'}>
        <Header/>
          <div id="page-content">
            {currentPage?.component}
            {!currentPage && (
              <div className="p-2 text-uppercase">
                <h1>{t('error.not-found')}</h1>
              </div>
            )}
          </div>
        <Footer/>
      </div>
    </div>
  );
}
