import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOut} from "@fortawesome/pro-duotone-svg-icons/faSignOut";
import React from "react";
import {useSetRecoilState} from "recoil";
import {loggedInAccountAtom} from "@stores/accountStore";
import {toggleNav} from "@helpers/toggleNav";
import {useTranslation} from "react-i18next";
import {useMoralis} from "react-moralis";

interface SignOutProps {
  className?: string;
}

export default function SignOut({className}: SignOutProps) {
  const {t} = useTranslation();
  const setLoggedInAccount = useSetRecoilState(loggedInAccountAtom);
  const { isAuthenticating, logout } = useMoralis();

  const logOut = () => {
    setLoggedInAccount(null);
    logout();
  }

  return (
    <button className={`btn btn-danger ${className || ''}`} onClick={() => {
      toggleNav();
      logOut();
    }} disabled={isAuthenticating}>
      <FontAwesomeIcon icon={faSignOut} className="me-2"/>
      {t('common.sign-out')}
    </button>
  );
}
