import {selector} from "recoil";
import {getRegistrationPrice} from "@contracts/HoneyGame";
import {convertStringToNumber} from "@helpers/bignumber";

export const registrationPriceState = selector<number | null>({
  key: 'registrationPrice',
  get: async () => {
    return convertStringToNumber(await getRegistrationPrice());
  },
});
