import React from "react";
import {numberWithCommas} from "@helpers/numbers";
import Placeholder from "@components/common/Placeholder";

export interface TranslateProps {
  value?: string | number | null;
  decimals?: number;
  floor?: boolean;
  className?: string;
  sign?: boolean;
  postfix?: string;
  postfixClass?: string;
  suffix?: string;
  suffixClass?: string;
}

const FormattedNumber = (props: TranslateProps) => {
  const {
    value,
    decimals = 2,
    floor,
    className,
    sign,
    postfix,
    postfixClass,
    suffix,
    suffixClass,
  } = props;


  if (value === undefined || value === null) {
    return (<span className={className}><Placeholder/></span>);
  }

  return (
    <span className={className}>
      {!!suffix && (
        <span className={suffixClass}>
          {suffix}
        </span>
      )}

      {sign && value > 0 && '+'}
      {numberWithCommas(value, floor, decimals)}
      {!!postfix && (
        <span className={postfixClass}>
          {['$', '%'].some(v => postfix.includes(v)) ? '' : ' '}
          {postfix}
        </span>
      )}
    </span>
  );
}

export default React.memo(FormattedNumber);
