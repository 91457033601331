import React, {useEffect, useState} from "react";
import Preloader from "@components/common/Preloader";
import FormattedNumber from "@components/common/FormattedNumber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faHandHoldingDollar} from "@fortawesome/pro-duotone-svg-icons/faHandHoldingDollar";
import {faCircleNotch} from "@fortawesome/pro-solid-svg-icons/faCircleNotch";
import {apiaryMoodSelector, apiarySelector, beeImages, useFetchApiary} from "@stores/beesStore";
import {useRecoilValue} from "recoil";
import config from 'config.json';
import {availableProfitSelector, loggedInAccountAtom, useUpdateAvailableProfit} from "@stores/accountStore";
import {toast} from "react-hot-toast";
import confirmAlert from "@components/ConfirmAlert";
import {claimProfit} from "@contracts/HoneyGame";
import IconWithLoading from "@components/common/IconWithLoading";
import {exchangeRateSelector, useUpdateBalance} from "@stores/bankStore";
import {useTranslation} from "react-i18next";
import BeesApiary from "@pages/Dashboard/components/BeesApiary";
import useInterval from "@hooks/useInterval";
import {availableProfit} from "@contracts/ApiaryLand";
import {multiply} from "@helpers/bignumber";

export default function Apiary() {
  const {t} = useTranslation();
  const initialProfit = useRecoilValue(availableProfitSelector);
  const userAddress = useRecoilValue(loggedInAccountAtom);
  const [profit, setProfit] = useState<string>('0');
  const apiary = useRecoilValue(apiarySelector);
  const mood = useRecoilValue(apiaryMoodSelector);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const rate = useRecoilValue(exchangeRateSelector);
  const updateBalance = useUpdateBalance();
  const fetchApiary = useFetchApiary();
  const updateProfit = useUpdateAvailableProfit();

  const moodClass = () => {
    if (!mood) {
      return 'bg-white';
    }

    if (mood < 40) {
      return 'bg-danger';
    } else if (mood < 80) {
      return 'bg-warning';
    } else {
      return 'bg-success';
    }
  };

  const handleClaim = () => {
    if (!profit || profit === '0') {
      toast.error(t('error.claim-not-enough'), {duration: 5000});
      return;
    }

    setIsLoading(true);

    confirmAlert({
      title: t('dashboard.claim-profit'),
      confirmation: (
        <>
          <FormattedNumber
            suffix={t('dashboard.claim-confirm')}
            suffixClass="me-1"
            value={profit}
            postfix="HNY?"
            floor={true}
            className={`d-block tx-bold`}
          />
        </>
      ),
      okLabel: t('common.claim'),
      okIcon: faHandHoldingDollar,
    }).then((result) => {
      if (!!result) {
        try {
          const waitPromise = claimProfit();

          toast.promise(waitPromise, {
            loading: t('alert.processing'),
            success: t('alert.claim-complete').replace('%profit', profit + ''),
            error: t('error.default'),
          });

          waitPromise
            .then(() => {
              updateBalance();
              fetchApiary();
              updateProfit();
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
            });
        } catch (e) {
          toast.error(t('error.default'), {duration: 5000});
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    })
  }

  useEffect(() => {
    if (initialProfit) {
      setProfit(initialProfit);
    }
  }, [initialProfit]);

  useInterval(() => {
    if (userAddress && initialProfit && apiary) {
      availableProfit(userAddress, apiary.bees.map((b) => b.count), apiary.items.map((i) => i?.itemId || 0))
        .then((_profit) => {
          setProfit(_profit);
        });
    }
  }, userAddress && initialProfit && apiary ? 5000 : null);

  return (
    <React.Suspense fallback={<Preloader/>}>
      <div>
        <div className="d-flex flex-row justify-content-start align-items-md-center tx-right mb-3 mb-md-0">
          <div className="tx-semibold">
            <FormattedNumber value={apiary?.usedSlots}/> {t('common.of')} <FormattedNumber value={apiary?.slots}/> {t('dashboard.hectares')}
          </div>

          <div className="ms-auto me-0">
            <span>{t('dashboard.available-profit')}:</span>

            <FormattedNumber
              postfix="HNY"
              value={profit}
              floor={true}
              className="d-block d-md-inline ms-1"
            />

            <FormattedNumber
              value={multiply(profit, rate)}
              floor={true}
              suffix="(~"
              postfix="$)"
              className="d-block d-md-inline ms-1 mb-2 mb-md-0"
            />

            <button
              className="btn btn-primary ms-md-2"
              disabled={!initialProfit || initialProfit === '0' || isLoading}
              onClick={handleClaim}
            >
              <IconWithLoading icon={faHandHoldingDollar} isLoading={isLoading}/>
              {t('common.claim')}
            </button>
          </div>
        </div>

        <div className="d-flex flex-column flex-md-row mt-3">
          <div className="d-flex flex-column order-2 order-md-1">
            {apiary?.bees.map((b, i) => b.count > 0 && (
              <div className={`card pos-relative rounded-10 wd-sm-200 ${i === 6 ? '' : 'mb-2'}`} key={`bee-${i}`}>
                <div className="card-body px-2 py-2">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <div className="tx-18 tx-bold">{config.bees[i].name}</div>
                      <div className="badge badge-pill bg-primary tx-dark">{t('common.qty')}: {b.count}</div>
                    </div>
                    <div className="d-flex align-items-center">
                      <img src={beeImages[i]} alt="bee" className="wd-70 ht-auto"/>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className={`wd-100p ${apiary?.bees.find((bee) => bee.count) ? 'ms-md-4' : ''} my-3 my-md-0 order-3 order-md-2 d-flex justify-content-center align-items-center`}>
            <BeesApiary/>
          </div>

          <div className="wd-100p wd-md-50 ht-50 ht-md-auto ms-md-4 mb-3 mb-md-0 order-1 order-md-3">
            <div className={`card ht-100p justify-content-center align-items-center ${!mood ? 'tx-dark' : 'tx-white'} ${moodClass()}`}>
              {!mood && <FontAwesomeIcon icon={faCircleNotch} className="tx-26" spin={!mood}/>}
              {!!mood && (
                <FormattedNumber
                  value={mood * 2}
                  floor={true}
                  decimals={0}
                  postfix="%"
                  className="tx-bold"
                />
              )}
            </div>
          </div>
        </div>

      </div>
    </React.Suspense>
  )
}
