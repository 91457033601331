import Moralis from "moralis";
import contract from './HoneyBox.json';
import contractPromise from "@helpers/contractPromise";
import BigNumber from "bignumber.js";
import {convertStringToNumber} from "@helpers/bignumber";

export const HoneypotBoxContract = {
  contractAddress: process.env.REACT_APP_HONEY_BOX_ADDRESS as string,
  abi: contract.abi,
};

export enum PrizeType {
  SLOTS = 0,
  TOKENS = 1,
  BEE = 2,
  NFT = 3,
}

export interface PrizeRaw {
  prizeType: PrizeType;
  value: number | BigNumber;
}

export interface Prize {
  prizeType: PrizeType;
  value: number;
}

export async function isWelcomeBoxAvailable(account: string):Promise<boolean> {
  const resp = await Moralis.executeFunction({
    functionName: 'isWelcomeBoxAvailable',
    params: {
      account,
    },
    ...HoneypotBoxContract
  });

  return resp as unknown as boolean;
}

export async function getLastWin(account: string):Promise<Prize> {
  const resp = await Moralis.executeFunction({
    functionName: 'getLastWin',
    params: {
      account,
    },
    ...HoneypotBoxContract
  }) as unknown as PrizeRaw;

  let value = resp.value;

  if (typeof(value) !== 'number' && resp.prizeType !== PrizeType.TOKENS) {
    value = value.toNumber();
  }

  if (typeof(value) !== 'number' && resp.prizeType === PrizeType.TOKENS) {
    value = convertStringToNumber(Moralis.Units.FromWei(value.toString()));
  }

  return {
    prizeType: resp.prizeType,
    value: value as number,
  }
}

export async function openWelcomeBox():Promise<boolean> {
  return contractPromise('openWelcomeBox', {}, HoneypotBoxContract);
}
