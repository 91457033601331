import {useCallback} from "react";
import {ApiaryRaw, getApiary, getApiaryMood, getUsedSlots} from "@contracts/ApiaryLand";
import {atom, selector, useRecoilState} from "recoil";
import {loggedInAccountAtom} from "@stores/accountStore";
import {convertStringToNumber, subtract} from "@helpers/bignumber";

import config from "config.json";
import bee1nft1 from "@assets/images/nfts/1/1.png";
import bee1nft2 from "@assets/images/nfts/1/2.png";
import bee1nft3 from "@assets/images/nfts/1/3.png";
import bee1nft4 from "@assets/images/nfts/1/4.png";
import bee1nft5 from "@assets/images/nfts/1/5.png";

import bee2nft1 from "@assets/images/nfts/2/1.png";
import bee2nft2 from "@assets/images/nfts/2/2.png";
import bee2nft3 from "@assets/images/nfts/2/3.png";
import bee2nft4 from "@assets/images/nfts/2/4.png";
import bee2nft5 from "@assets/images/nfts/2/5.png";

import bee3nft1 from "@assets/images/nfts/3/1.png";
import bee3nft2 from "@assets/images/nfts/3/2.png";
import bee3nft3 from "@assets/images/nfts/3/3.png";
import bee3nft4 from "@assets/images/nfts/3/4.png";
import bee3nft5 from "@assets/images/nfts/3/5.png";

import bee4nft1 from "@assets/images/nfts/4/1.png";
import bee4nft2 from "@assets/images/nfts/4/2.png";
import bee4nft3 from "@assets/images/nfts/4/3.png";
import bee4nft4 from "@assets/images/nfts/4/4.png";
import bee4nft5 from "@assets/images/nfts/4/5.png";

import bee5nft1 from "@assets/images/nfts/5/1.png";
import bee5nft2 from "@assets/images/nfts/5/2.png";
import bee5nft3 from "@assets/images/nfts/5/3.png";
import bee5nft4 from "@assets/images/nfts/5/4.png";
import bee5nft5 from "@assets/images/nfts/5/5.png";

import bee6nft1 from "@assets/images/nfts/6/1.png";
import bee6nft2 from "@assets/images/nfts/6/2.png";
import bee6nft3 from "@assets/images/nfts/6/3.png";
import bee6nft4 from "@assets/images/nfts/6/4.png";
import bee6nft5 from "@assets/images/nfts/6/5.png";

import bee7nft1 from "@assets/images/nfts/7/1.png";
import bee7nft2 from "@assets/images/nfts/7/2.png";
import bee7nft3 from "@assets/images/nfts/7/3.png";
import bee7nft4 from "@assets/images/nfts/7/4.png";
import bee7nft5 from "@assets/images/nfts/7/5.png";
import bee1 from "@assets/images/bees/bee-1.png";
import bee2 from "@assets/images/bees/bee-2.png";
import bee3 from "@assets/images/bees/bee-3.png";
import bee4 from "@assets/images/bees/bee-4.png";
import bee5 from "@assets/images/bees/bee-5.png";
import bee6 from "@assets/images/bees/bee-6.png";
import bee7 from "@assets/images/bees/bee-7.png";

export const beeImages = [bee1, bee2, bee3, bee4, bee5, bee6, bee7];
export const BeeName = config.bees.map((bee) => bee.name);

export enum SetType {
  COMMON = 'common',
  UNCOMMON = 'uncommon',
  MASTER = 'master',
  EPIC = 'epic',
}

export interface Item {
  itemId: number;
  beeId: number;
  name: string;
  price: number;
  bonusPercents: number;
}

export interface NFT extends Item {
  image: string;
  itemSet: number | null;
  amount: number;
}

export interface ItemsSet {
  setId: number;
  type: string;
  name: string;
  bonusPercents: number;
  items: Item[];
}

export interface UserBee {
  count: number;
  item: number;
}

export interface Apiary {
  slots: number;
  usedSlots: number;
  bees: Array<UserBee>;
  items: Array<NFT | null>
}

export interface Bee {
  id: number;
  name: string;
  baseDailyProfit: number;
  maxDailyProfit: number;
  price: number;
  slots: number;
}

export interface UserNFT {
  id: number;
  count: number;
}

export const fetchApiaryAtom = atom<number>({
  key: 'fetchApiaryAtom',
  default: 0,
});

export const usedSlotsSelector = selector<number | null>({
  key: 'usedSlotsSelector',
  get: async ({get}) => {
    get(fetchApiaryAtom);
    const userAddress = get(loggedInAccountAtom);

    if (!userAddress) {
      return null;
    }

    return await getUsedSlots(userAddress);
  },
});

export const apiaryRawSelector = selector<ApiaryRaw | null>({
  key: 'apiaryRawSelector',
  get: async ({get}) => {
    get(fetchApiaryAtom);
    const userAddress = get(loggedInAccountAtom);

    if (!userAddress) {
      return null;
    }

    return await getApiary(userAddress);
  },
});

export const apiarySelector = selector<Apiary | null>({
  key: 'apiarySelector',
  get: async ({get}) => {
    get(fetchApiaryAtom);
    const apiary = get(apiaryRawSelector);
    const usedSlots = get(usedSlotsSelector);

    if (!apiary || usedSlots === null) {
      return null;
    }

    const items: Array<NFT | null> = apiary.items.map((item) => getNft(item.toNumber(), 1));

    return {
      slots: apiary.slots.toNumber(),
      usedSlots,
      bees: apiary.bees.map((count, index) => ({count: count.toNumber(), item: apiary.items[index].toNumber()})),
      items,
    };
  },
});

export const emptySlotsSelector = selector<number | null>({
  key: 'emptySlotsSelector',
  get: async ({get}) => {
    const apiary = get(apiarySelector);

    if (!apiary) {
      return null;
    }

    return convertStringToNumber(subtract(apiary.slots, apiary.usedSlots));
  },
});

export const isRegisteredSelector = selector<boolean>({
  key: 'isRegisteredSelector',
  get: async ({get}) => {
    const apiary = get(apiarySelector);

    if (!apiary) {
      return false;
    }

    return apiary.slots !== 0;
  },
});

export const apiaryMoodSelector = selector<number | null>({
  key: 'apiaryMoodSelector',
  get: async ({get}) => {
    get(fetchApiaryAtom);
    const userAddress = get(loggedInAccountAtom);

    if (!userAddress) {
      return null;
    }

    const mood = await getApiaryMood(userAddress);
    const moodValue = mood === -10000 ? -9999 : mood

    return 100 / 20000 * (moodValue + 10000);
  },
});

export const getNft = (id: number, amount = 0): NFT | null => {
  const image = getNftImage(id);
  let itemSet = null;
  let item: Item | null = null;

  config.sets.forEach((_set) => {
    _set.items.forEach((_item) => {
      if (_item.itemId === id) {
        item = _item;
        itemSet = _set.setId;
      }
    });
  });

  return itemSet !== null ? {
    image: image || '',
    itemSet: itemSet,
    amount,
    ...item!,
  } : null;
}

export const getNftImage = (id: number): string | null => {
  switch (id) {
    case 1:
      return bee1nft1;
    case 2:
      return bee2nft1;
    case 3:
      return bee3nft1;
    case 4:
      return bee4nft1;
    case 5:
      return bee5nft1;
    case 6:
      return bee6nft1;
    case 7:
      return bee7nft1;
    case 8:
      return bee1nft2;
    case 9:
      return bee2nft2;
    case 10:
      return bee3nft2;
    case 11:
      return bee4nft2;
    case 12:
      return bee5nft2;
    case 13:
      return bee6nft2;
    case 14:
      return bee7nft2;
    case 15:
      return bee1nft3;
    case 16:
      return bee2nft3;
    case 17:
      return bee3nft3;
    case 18:
      return bee4nft3;
    case 19:
      return bee5nft3;
    case 20:
      return bee6nft3;
    case 21:
      return bee7nft3;
    case 22:
      return bee1nft4;
    case 23:
      return bee2nft4;
    case 24:
      return bee3nft4;
    case 25:
      return bee4nft4;
    case 26:
      return bee5nft4;
    case 27:
      return bee6nft4;
    case 28:
      return bee7nft4;
    case 29:
      return bee1nft5;
    case 30:
      return bee2nft5;
    case 31:
      return bee3nft5;
    case 32:
      return bee4nft5;
    case 33:
      return bee5nft5;
    case 34:
      return bee6nft5;
    case 35:
      return bee7nft5;
    default:
      return null;
  }
}

export const useFetchApiary = () => {
  const [fetchCount, setFetchCount] = useRecoilState(fetchApiaryAtom);

  return useCallback(() => {
    setFetchCount(fetchCount + 1);
  }, [fetchCount, setFetchCount]);
}

export default function BeesStore() {
  return (<></>);
}
