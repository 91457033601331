import React from "react";
import Preloader from "@components/common/Preloader";
import {apiaryMoodSelector, apiarySelector, beeImages} from "@stores/beesStore";
import {useRecoilValue} from "recoil";
import apiaryBg from "@assets/images/apiary/bg.png";
import apiaryBeeItem1 from "@assets/images/apiary/1.png";
import apiaryBeeItem2 from "@assets/images/apiary/2.png";
import apiaryBeeItem3 from "@assets/images/apiary/3.png";
import apiaryBeeItem4 from "@assets/images/apiary/4.png";
import apiaryBeeItem5 from "@assets/images/apiary/5.png";
import apiaryBeeItem6 from "@assets/images/apiary/6.png";
import apiaryBeeItem7 from "@assets/images/apiary/7.png";

export default function BeesApiary() {
  const apiary = useRecoilValue(apiarySelector);
  const mood = useRecoilValue(apiaryMoodSelector);
  const images = [apiaryBeeItem1, apiaryBeeItem2, apiaryBeeItem3, apiaryBeeItem4, apiaryBeeItem5, apiaryBeeItem6, apiaryBeeItem7];

  return (
    <React.Suspense fallback={<Preloader/>}>
      <div className="pos-relative card p-0 overflow-hidden" id="apiary-container">
        <img src={apiaryBg}/>
        {!!mood && mood >= 0.5 && apiary?.bees.map((bee, index) => !!bee.count && (
          <React.Fragment key={`apiary-bee-${index}`}>
            <img alt={`apiary-bee-item-${index}`} src={images[index]} className="apiary-bee-item"/>
            <img alt={`apiary-bee-${index}`} src={beeImages[index]} className={`apiary-bee apiary-bee-${index + 1}`}/>
          </React.Fragment>
        ))}
      </div>
    </React.Suspense>
  )
}
