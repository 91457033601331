import React from "react";
import logo from "@assets/images/logo.png";
import {Link, useLocation} from "react-router-dom";
import Dashboard from "@pages/Dashboard/Dashboard";
import {useTranslation} from "react-i18next";
import SignOut from "@components/common/SignOut";
import {toggleNav} from "@helpers/toggleNav";
import ShopPage from "@pages/Shop/ShopPage";
import HoneyBank from "@pages/HoneyBank/HoneyBank";
import {useRecoilValue} from "recoil";
import {exchangeRateSelector, usdtBalanceSelector, userBalanceSelector} from "@stores/bankStore";
import FormattedNumber from "@components/common/FormattedNumber";
import {multiply} from "@helpers/bignumber";
import Bonus from "@pages/Bonus/Bonus";
import StoragePage from "@pages/Storage/StoragePage";
import LanguageSwitcher from "@components/common/language-switcher/LanguageSwitcher";
import {ApiaryIcon, BankIcon, GiftIcon, MarketIcon, MoneyIcon, WalletIcon} from "@assets/svg-icons";

export enum Page {
  DASHBOARD = 'dashboard',
  STORAGE = 'storage',
  SHOP = 'shop',
  HONEY_BANK = 'honeybank',
  BONUS = 'bonus',
}

export enum PagePath {
  DASHBOARD = '/',
  SHOP = '/shop',
  STORAGE = '/storage',
  HONEY_BANK = '/honeybank',
  BONUS = '/bonus',
}

interface NavPage {
  id: Page;
  path: PagePath;
  title: string;
  component: JSX.Element;
  icon: JSX.Element;
}

export const NavPages: Array<NavPage> = [
  {
    id: Page.DASHBOARD,
    path: PagePath.DASHBOARD,
    title: 'dashboard.title',
    component: <Dashboard/>,
    icon: <ApiaryIcon/>,
  },
  {
    id: Page.STORAGE,
    path: PagePath.STORAGE,
    title: 'storage.title',
    component: <StoragePage/>,
    icon: <MarketIcon/>,
  },
  {
    id: Page.SHOP,
    path: PagePath.SHOP,
    title: 'shop.title',
    component: <ShopPage/>,
    icon: <MoneyIcon/>,
  },
  {
    id: Page.HONEY_BANK,
    path: PagePath.HONEY_BANK,
    title: 'bank.title',
    component: <HoneyBank/>,
    icon: <BankIcon/>,
  },
  {
    id: Page.BONUS,
    path: PagePath.BONUS,
    title: 'bonus.title',
    component: <Bonus/>,
    icon: <GiftIcon/>,
  },
];

export default function Sidebar() {
  const {t} = useTranslation();
  const location = useLocation();
  const hnyBalance = useRecoilValue(userBalanceSelector);
  const usdtBalance = useRecoilValue(usdtBalanceSelector);
  const rate = useRecoilValue(exchangeRateSelector);

  return (
    <div id="sidebar" className="d-flex flex-column pe-lg-3">
      <div className="d-flex flex-column ht-100p wd-100p p-2 pt-3 p-lg-4 bg-gray-100">
        <div className="logo tx-center mt-3">
          <Link to="/">
            <img src={logo} title="Honeypot Game" alt="Honeypot Game" width={150}/>
          </Link>
        </div>

        <div className="mt-3 mb-auto pb-2">
          <div className="card ht-auto-f">
            <div className="card-body p-2">
              <div className="tx-bold tx-18 tx-center">{t('sidebar.honeypot-balance')}:</div>
              <div className="d-flex flex-row justify-content-between mt-2">
                <div className="wd-50 pe-2">
                  <WalletIcon/>
                </div>
                <div>
                  <FormattedNumber
                    value={hnyBalance}
                    postfix="HNY"
                    className="d-block"
                    postfixClass="tx-bold"
                    floor={true}
                  />
                  <FormattedNumber
                    value={multiply(hnyBalance, rate)}
                    floor={true}
                    suffix="(~"
                    postfix="$)"
                    className="d-block"
                  />
                  <FormattedNumber
                    value={usdtBalance}
                    postfix="USDT"
                    postfixClass="tx-bold"
                    className="d-block"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <nav id="sidebar-nav" className="mb-auto mt-4 mt-lg-0">
          {NavPages.map((nav) => {
            const isActive = location.pathname === nav.path;

            return (
              <Link
                className={`btn d-flex flex-row justify-content-start align-items-center ${isActive ? 'btn-primary' : 'btn-transparent'}`}
                to={nav.path}
                key={nav.id}
                onClick={toggleNav}
              >
                <div className="sidebar-icon wd-30 ms-2 me-2">
                  {nav.icon}
                </div>
                {t(nav.title)}
              </Link>
            )
          })}
        </nav>

        <div className="d-flex flex-column align-items-center justify-content-center">
          <LanguageSwitcher/>

          <SignOut className="btn btn-danger d-lg-none"/>
        </div>
      </div>
    </div>
  )
}
