import Moralis from "moralis";
import contract from './HoneyToken.json';
import {convertStringToNumber} from "@helpers/bignumber";

export const HoneTokenContract = {
  contractAddress: process.env.REACT_APP_HONEYPOT_TOKEN_CONTRACT_ADDRESS as string,
  abi: contract.abi,
};

export async function getBalance(userAddress: string):Promise<number> {
  let resp = await Moralis.executeFunction({
    functionName: 'balanceOf',
    params: {
      account: userAddress,
    },
    ...HoneTokenContract
  });

  return convertStringToNumber(Moralis.Units.FromWei(resp as unknown as string));
}
