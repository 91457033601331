import React, {useCallback, useEffect, useState, useTransition} from "react";
import Preloader from "@components/common/Preloader";
import {useRecoilValue} from "recoil";
import useCurrentPageTitle from "@hooks/useCurrentPageTitle";
import {
  apiarySelector,
  getNft,
  isRegisteredSelector,
  NFT, useFetchApiary,
} from "@stores/beesStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormattedNumber from "@components/common/FormattedNumber";
import {faSave} from "@fortawesome/pro-solid-svg-icons/faSave";
import {faCircleNotch} from "@fortawesome/pro-solid-svg-icons/faCircleNotch";
import Register from "@pages/Register";
import {toast} from "react-hot-toast";
import {setApiaryItems} from "@contracts/HoneyGame";
import {useTranslation} from "react-i18next";
import confirmAlert from "@components/ConfirmAlert";
import config from "config.json";
import {faArrowRightLongToLine} from "@fortawesome/pro-duotone-svg-icons/faArrowRightLongToLine";
import {convertStringToNumber} from "@helpers/bignumber";
import {loggedInAccountAtom} from "@stores/accountStore";
import {useMoralisWeb3Api} from "react-moralis";
import GameCard from "@pages/Storage/components/GameCard";

export default function StoragePage() {
  const {t} = useTranslation();
  const [isPending] = useTransition();
  const pageTitle = useCurrentPageTitle();
  const apiary = useRecoilValue(apiarySelector);
  const [availableItems, setAvailableItems] = useState<NFT[] | null>(null)
  const [equipped, setEquipped] = useState<Array<NFT | null> | null>(null);
  const [items, setItems] = useState<Array<NFT[]> | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isRegistered = useRecoilValue(isRegisteredSelector);
  const fetchApiary = useFetchApiary();
  const userAddress = useRecoilValue(loggedInAccountAtom);
  const Web3Api = useMoralisWeb3Api();

  useEffect(() => {
    if (availableItems && equipped && !items) {
      const newItems: Array<NFT[]> = [[], [], [], [], [], [], []];

      availableItems.forEach((item) => {
        const bee = item.beeId - 1;
        newItems[bee].push({
          ...item,
          amount: equipped && equipped[bee] && equipped[bee]?.itemId === item.itemId ? item.amount + 1 : item.amount,
        });
      });

      equipped.forEach((eq, index) => {
        const exist = newItems[index].find((x) => eq && x.itemId === eq.itemId);

        if (!exist && eq) {
          newItems[index].push({
            ...eq,
            amount: 1,
          });
        }
      });

      setItems(newItems);
    }
  }, [availableItems, equipped, items]);

  const handleEquip = useCallback((item: NFT | null, index: number) => {
    if (!apiary?.bees[index].count) {
      toast.error(t('error.no-bee-available'), {duration: 5000});
      return;
    }

    setEquipped((prevState) => {
      let newState: Array<NFT | null> = [];

      if (prevState) {
        newState = [...prevState];
        newState[index] = item;
      }

      return newState;
    });
  }, [apiary, t]);

  const handleSave = () => {
    if (!equipped) {
      return;
    }

    setIsLoading(true);

    confirmAlert({
      title: t('alert.confirm-equip'),
      confirmation: (
        <>
          {equipped.map((item, index) => {
            const equippedItem = item ? getNft(item.itemId) : null
            const originalItemId = apiary?.bees[index].item;
            const originalItem = originalItemId ? getNft(originalItemId) : null;
            const beeName = config.bees[index].name;
            const isBigger = equippedItem && originalItem
              ? equippedItem.bonusPercents > originalItem.bonusPercents
              : !originalItem && equippedItem;

            return (!item && !originalItem) || item?.itemId === originalItemId ? null : (
              <div className="card wd-100p mb-3" key={`item-comparison-${index}`}>
                <div className="card-title tx-center pt-2 pb-1 tx-bold tx-30">
                  {beeName}
                </div>
                <div className="card-body pt-1 pb-3 d-flex flex-row justify-content-between align-items-center">
                  <div>
                    <div className="tx-bold wd-100p">
                      {!originalItem ? t('common.empty-slot') : originalItem.name}
                    </div>
                    <div className={`badge badge-pill ${isBigger ? 'bg-danger' : 'bg-success'} d-inline-block`}>
                      <FormattedNumber
                        value={originalItem?.bonusPercents || 0}
                        suffix="+"
                        postfix="%"
                      />
                    </div>
                  </div>

                  <FontAwesomeIcon icon={faArrowRightLongToLine} className="mx-4 tx-20"/>

                  <div>
                    <div className="tx-bold wd-100p">
                      {!equippedItem ? t('common.empty-slot') : equippedItem.name}
                    </div>
                    <div className={`badge badge-pill ${!isBigger ? 'bg-danger' : 'bg-success'} d-inline-block`}>
                      <FormattedNumber
                        value={equippedItem?.bonusPercents || 0}
                        suffix="+"
                        postfix="%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </>
      ),
      okLabel: t('common.save'),
      okIcon: faSave,
    }).then((result) => {
      if (!!result) {
        try {
          const waitPromise = setApiaryItems(equipped.map((item) => item?.itemId || 0));

          toast.promise(waitPromise, {
            loading: t('alert.processing'),
            success: t('alert.items-saved'),
            error: t('error.default'),
          });

          waitPromise
            .then(() => {
              fetchApiary();
              setIsLoading(false);
            })
            .catch(() => {
              setIsLoading(false);
            });
        } catch (e) {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    })
    .catch(() => {
      setIsLoading(false);
    });
  };

  const saveButton = (
      <button
        className="btn btn-primary"
        disabled={isLoading}
        onClick={handleSave}
      >
        <FontAwesomeIcon
          icon={isLoading ? faCircleNotch : faSave}
          className="me-2"
          spin={isLoading}
        />
        {t('common.save')}
      </button>
  );

  useEffect(() => {
    if (Web3Api && userAddress && !availableItems) {
      const options = {
        chain: '0x' + convertStringToNumber(process.env.REACT_APP_CHAIN_ID!).toString(16),
        address: userAddress,
        token_address: process.env.REACT_APP_BEE_ITEM_CONTRACT_ADDRESS!,
      };

      // @ts-ignore
      Web3Api.account.getNFTsForContract(options).then((result) => {
        const available: NFT[] = [];
        result.result?.forEach((nft) => {
          const found = getNft(convertStringToNumber(nft.token_id), convertStringToNumber(nft.amount || 0));
          if (found) {
            available.push(found);
          }
        });

        if (available) {
          setAvailableItems(available);
        }
      });
    }
  }, [userAddress, availableItems, Web3Api]);

  useEffect(() => {
    if (!equipped && apiary) {
      let array = [null, null, null, null, null, null, null];
      setEquipped(array.map((v, index) => apiary.items[index] || null));
    }
  }, [apiary, equipped]);

  return (
    <React.Suspense fallback={<Preloader/>}>
      <div className="container-fluid pb-4 ps-lg-0 pe-lg-4">
        <div className="d-flex d-md-none align-items-center mb-4 justify-content-between">
          <h1 className="mb-0">{pageTitle}</h1>

          <div>{isRegistered ? saveButton : null}</div>
        </div>

        {!isRegistered ? (<Register/>) : (
          <>
            <div className="wd-100p d-none d-md-flex justify-content-end mb-4 tx-right">
              {saveButton}
            </div>
            <div className="wd-100p mt-2 mt-lg-4 mb-4" id="storage-bees-wrapper">
              <div>
                {apiary?.bees.map((bee, beeIndex) => {
                  const equippedItem = equipped && equipped[beeIndex] ? equipped[beeIndex] : null;
                  const beeName = config.bees[beeIndex]?.name;

                  return (
                    <div className="slider-item" key={`storage-bee-${beeIndex}`}>
                      <div className="card tx-center">
                        <div className="card-title tx-bold tx-28">{beeName}</div>

                        <div className="card-body pt-0">
                          <div className="tx-semibold">{t('common.equipped-item')}:</div>
                          <div>
                            {equippedItem ? (
                              <GameCard
                                onClick={() => handleEquip(null, beeIndex)}
                                item={equippedItem}
                                itemSet={config.sets.find((_set) => _set.setId === equippedItem.itemSet)!}
                                beeName={beeName}
                              />
                            ) : t('common.empty-slot')}
                          </div>
                        </div>
                      </div>

                      {items && (
                        <div className="card mt-3 mt-md-5 mb-4 tx-center">
                          <div className="card-title tx-bold tx-18 pt-2">{t('common.available-items')}:</div>
                          <div>
                            {items[beeIndex].map((item, itemIndex, array) => {
                              const isEquipped = equipped && equipped[beeIndex] && equipped[beeIndex]?.itemId === item.itemId;
                              const amount = isEquipped ? item.amount - 1 : item.amount;
                              const itemSet = config.sets.find((_set) => _set.setId === item.itemSet);

                              return !amount || !itemSet ? null : (
                                <GameCard
                                  key={`bee-${beeIndex}-available-item-${item.itemId}`}
                                  onClick={() => handleEquip(item, beeIndex)}
                                  item={item}
                                  itemSet={itemSet}
                                  beeName={config.bees[beeIndex]?.name}
                                  amount={amount}
                                />
                              );
                            })}
                            {items[beeIndex].filter((item) => {
                              const isEquipped = equipped && equipped[beeIndex] && equipped[beeIndex]?.itemId === item.itemId;
                              const amount = isEquipped ? item.amount - 1 : item.amount;
                              return !!amount;
                            }).length === 0 && (
                              <div className="tx-semibold py-2">{t('common.no-available-items')}</div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          </>
        )}
      </div>

      {(isPending) && <Preloader/>}
    </React.Suspense>
  )
}
