import React, {useState, useTransition} from "react";
import Preloader from "@components/common/Preloader";
import FormattedNumber from "@components/common/FormattedNumber";
import {isRegisteredSelector} from "@stores/beesStore";
import {useRecoilValue} from "recoil";
import useCurrentPageTitle from "@hooks/useCurrentPageTitle";
import {exchangeRateSelector} from "@stores/bankStore";
import Apiary from "@pages/Dashboard/components/Apiary";
import Register from "@pages/Register";
import {profitSelector} from "@stores/accountStore";
import {useTranslation} from "react-i18next";

export default function Dashboard() {
  const {t} = useTranslation();
  const [isPending] = useTransition();
  const profitValues = useRecoilValue(profitSelector);
  const rate = useRecoilValue(exchangeRateSelector);
  const pageTitle = useCurrentPageTitle();
  const isRegistered = useRecoilValue(isRegisteredSelector);
  const [tab, setTab] = useState<number>(0);

  const profits = [
    {
      title: t('dashboard.profit-yearly'),
      tick: t('dashboard.tick-yearly'),
    },
    {
      title: t('dashboard.profit-monthly'),
      tick: t('dashboard.tick-monthly'),
    },
    {
      title: t('dashboard.profit-weekly'),
      tick: t('dashboard.tick-weekly'),
    },
    {
      title: t('dashboard.profit-daily'),
      tick: t('dashboard.tick-daily'),
    },
  ];

  return (
    <React.Suspense fallback={<Preloader/>}>
      <div className="container-fluid pb-4 ps-lg-0 pe-lg-4">
        <div className="d-flex d-md-none align-items-center mb-4">
          <h1 className="mb-0">{pageTitle}</h1>
        </div>

        {isRegistered && (<>
          <div className="d-md-none mb-4">
            <div className="card rounded-20">
              <div className="card-title pt-2 pb-0 tx-center">
                <div className="h3 mb-0">{t('dashboard.profit')}</div>
              </div>
              <div className="card-body pt-0">
                <div className="btn-group wd-100p mb-3" role="group" aria-label="Basic example">
                  {profits.slice().reverse().map((p, i) => (
                    <button
                      type="button"
                      className={`btn ${i === tab ? 'btn-dark' : 'btn-secondary'}`}
                      key={`profit-tab-btn-${i}`}
                      onClick={() => setTab(i)}
                    >
                      {t(p.tick)}
                    </button>
                  ))}
                </div>

                {profits.slice().reverse().map((p, i) => i === tab && (
                  <div className="tx-center tx-bold tx-18" key={`profit-tab-${i}`}>
                    <FormattedNumber value={profitValues ? profitValues[3 - i] : 0} floor={false} postfix="HNY" className="d-block"/>
                    <FormattedNumber
                      value={profitValues && rate ? profitValues[3 - i] * rate : 0}
                      floor={false}
                      suffix="(~"
                      postfix="$)"
                      className="d-block"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="row d-none d-md-flex">
            {profits.map((p, i) => (
              <div className={`col-md-3 mb-4`} key={`profit-${i}`}>
                <div className={`card wd-100p tx-center`}>
                  <div className="card-title tx-bold">{p.title}</div>
                  <div className="card-body pt-1">
                    <FormattedNumber value={profitValues ? profitValues[i] : 0} floor={false} postfix="HNY" className="d-block"/>
                    <FormattedNumber
                      value={profitValues && rate ? profitValues[i] * rate : 0}
                      floor={false}
                      suffix="(~"
                      postfix="$)"
                      className="d-block"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>)}
        {isRegistered && (<Apiary/>)}
        {!isRegistered && (<Register/>)}
      </div>

      {(isPending) && <Preloader/>}
    </React.Suspense>
  )
}
