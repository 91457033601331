import React, {useEffect, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/pro-solid-svg-icons/faCircleNotch";
import {useRecoilValue} from "recoil";
import {
  loggedInAccountAtom,
  refState,
} from "@stores/accountStore";
import {toast} from "react-hot-toast";
import {registrationPriceState} from "@stores/contract";
import {useTranslation} from "react-i18next";
import {useIsBalanceEnough, useUpdateBalance} from "@stores/bankStore";
import {useFetchApiary} from "@stores/beesStore";
import {Modal} from "react-bootstrap";
import {faOctagonPlus} from "@fortawesome/pro-solid-svg-icons/faOctagonPlus";
import FormattedNumber from "@components/common/FormattedNumber";
import {faExclamationTriangle} from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import {faCartPlus} from "@fortawesome/pro-solid-svg-icons/faCartPlus";
import {useNavigate} from "react-router-dom";
import {PagePath} from "@components/Sidebar";
import {faTimes} from "@fortawesome/pro-solid-svg-icons/faTimes";
import {createApiary, getRefAddress} from "@contracts/HoneyGame";
import IconWithLoading from "@components/common/IconWithLoading";
import bee4 from "@assets/images/bees/bee-4.png";
import svgTop from "@assets/images/create-apiary-top.svg";
import svgBottom from "@assets/images/create-apiary-bottom.svg";
import config from "config.json";
import Translate from "@components/common/Translate";

export default function Register() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isRefAddressLoading, setRefAddressLoading] = useState<boolean>(false);
  const userAddress = useRecoilValue(loggedInAccountAtom);
  const registrationPrice = useRecoilValue(registrationPriceState);
  const configRegistrationPrice = config.registrationPrice;
  const refIdState = useRecoilValue(refState);
  const [refId, setRefId] = useState<string>(refIdState || '');
  const [refAddress, setRefAddress] = useState<string>('');
  const [regForAdmin, setForAdmin] = useState<boolean>(false);
  const isEnough = useIsBalanceEnough();
  const fetchApiary = useFetchApiary();
  const updateBalance = useUpdateBalance();
  const addressRegExp = /^0x[0-9a-f]{40}$/i;

  const isEnoughTokens = isEnough(registrationPrice!);

  const resetForm = () => {
    setLoading(false);
    setForAdmin(false);
  };

  const handleClose = () => {
    resetForm();
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const handleNavigate = () => {
    handleClose();
    navigate(PagePath.HONEY_BANK);
  };

  const register = () => {
    if (!refAddress || refAddress === '0x0000000000000000000000000000000000000000') {
      toast.error(t('error.approve-upline'), {duration: 5000});
      return;
    }

    if (refAddress === userAddress) {
      toast.error(t('error.default'), {duration: 5000});
      return;
    }

    if (!isEnoughTokens) {
      return;
    }

    setLoading(true);

    try {
      const waitPromise = createApiary(refAddress);

      toast.promise(waitPromise, {
        loading: t('alert.create-apiary-loading'),
        success: t('alert.create-apiary-complete') + '',
        error: t('alert.create-apiary-error'),
      });

      waitPromise
        .then(() => {
          updateBalance();
          fetchApiary();
          setLoading(false);
          handleClose();
        })
        .catch(() => {
          setLoading(false);
        });
    } catch (e) {
      toast.error(t('error.default'), {duration: 5000});
      setLoading(false);
    }
  };

  const loadRefAddress = (customRef?: string, isAuto = false) => {
    if (!refId && !customRef) {
      return;
    } else if (customRef) {
      setForAdmin(true);
    }
    if (addressRegExp.test(refId)) {
      setRefAddress(refId);
      return;
    }

    setRefAddressLoading(true);

    try {
      getRefAddress(customRef || refId || '')
        .then((result) => {
          if (result && result !== '0x0000000000000000000000000000000000000000') {
            setRefAddress(result);
          } else {
            if (isAuto) {
              setRefId('');
            } else {
              toast.error(t('error.user-id-not-found'), {duration: 5000});
            }
          }
          setRefAddressLoading(false);
        })
        .catch(() => {
          if (isAuto) {
            setRefId('');
          } else {
            toast.error(t('error.user-id-not-found'), {duration: 5000});
          }
          setRefAddressLoading(false);
        });
    } catch (e) {
      if (isAuto) {
        setRefId('');
      } else {
        toast.error(t('error.default'), {duration: 5000});
      }
      setRefAddressLoading(false);
    }
  };

  useEffect(() => {
    if (regForAdmin && refAddress && !isRefAddressLoading) {
      register();
    }
  }, [regForAdmin, refAddress, isRefAddressLoading]);

  useEffect(() => {
    if (refIdState && !refAddress) {
      loadRefAddress(undefined, true);
    }
  }, [refIdState, refAddress]);

  return (
    <>
      <div className="card bg-purple p-4" id="card-create-apiary">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <div className="wd-md-400 tx-36 tx-extrabold tx-white">
              {t('register.no-apiary')}
            </div>
            <div className="wd-md-400 tx-18 tx-white mt-3">
              {t('register.no-apiary-sub')}
            </div>

            <button
              className="btn btn-primary mt-5"
              onClick={handleShow}
              disabled={isLoading}
            >
              <IconWithLoading icon={faOctagonPlus} className="me-2" isLoading={isLoading}/>
              {t('common.buy-apiary')}
            </button>
          </div>
          <div className="d-none d-md-block">
            <img src={bee4} alt="bee" />
          </div>
        </div>
        <div className="apiary-card-svg">
          <img src={svgTop} className="svg-top"/>
          <img src={svgBottom} className="svg-bottom"/>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="pb-3" closeButton>
          <Modal.Title>
            {t('common.buy-apiary')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          {isEnoughTokens && (
            <div id="upline">
              <div className="tx-bold mb-1 tx-16">{t('register.upline-address-and-id')}</div>

              <div className="mb-1">
                {isRefAddressLoading && (
                  <FontAwesomeIcon
                    className="me-2"
                    icon={faCircleNotch}
                    spin
                  />
                )}
                {!refAddress && 'You need to approve your upline'}
                {!!refAddress && (
                  <div className="tx-12 word-break">
                    {t('register.upline-address')} {refAddress}
                  </div>
                )}
              </div>

              <div className="d-lg-flex mb-1">
                <input
                  type="text"
                  className={`form-control mb-0 me-2 flex-1 ${refAddress ? 'is-valid' : ''}`}
                  placeholder={t('register.upline-input')}
                  disabled={isLoading || isRefAddressLoading}
                  value={refId}
                  onChange={(event) => setRefId(event.target.value)}
                />
                <button
                  className="btn btn-primary mt-2 mt-lg-0"
                  type="button"
                  onClick={() => loadRefAddress()}
                  disabled={isLoading || isRefAddressLoading || !refId}
                >
                  {t('register.approve-upline')}
                </button>
              </div>

              <div className="mb-1 tx-bold tx-16">
                <Translate i18nKey="register.no-upline"/>
              </div>

            </div>
          )}

          <div className="tx-bold tx-18">
            {`${t('register.apiary-price')}: `}
            <FormattedNumber
              value={configRegistrationPrice !== registrationPrice ? configRegistrationPrice : registrationPrice}
              className={`${configRegistrationPrice !== registrationPrice ? 'tx-crossed me-2': ''}`}
              postfix="HNY"
            />
            {configRegistrationPrice !== registrationPrice && (
              <FormattedNumber
                value={registrationPrice}
                postfix="HNY"
              />
            )}
          </div>

          {!isEnoughTokens && (
            <div className="tx-danger tx-bold tx-18 mt-2">
              <div>
                <FontAwesomeIcon icon={faExclamationTriangle} className="me-1"/>
                {t('error.not-enough-tokens')}
                <button className="btn btn-link tx-danger tx-bold ms-1 tx-18 py-0 px-0 mb-1" onClick={handleNavigate}>
                  {t('error.not-enough-tokens-link')}
                </button>
              </div>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer className="pt-3 wide-buttons">
          <button className="btn btn-dark" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} className="me-2"/>
            {t('common.close')}
          </button>

          {!isEnoughTokens && (
            <button className="btn btn-primary ms-3" onClick={handleNavigate}>
              {t('common.buy-tokens')}
              <FontAwesomeIcon icon={faCartPlus} className="float-end ms-2"/>
            </button>
          )}

          {isEnoughTokens && (
            <button className="btn btn-primary ms-3" onClick={register} disabled={isLoading}>
              {t('common.buy-apiary')}
              <IconWithLoading icon={faOctagonPlus} className="float-end ms-2" isLoading={isLoading}/>
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
