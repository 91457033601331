import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Modal} from "react-bootstrap";
import {faTimes} from "@fortawesome/pro-solid-svg-icons/faTimes";
import {faCheck} from "@fortawesome/pro-solid-svg-icons/faCheck";
import {IconDefinition} from "@fortawesome/pro-solid-svg-icons";
import {useState} from "react";
import useInterval from "@hooks/useInterval";
import {faCircleNotch} from "@fortawesome/pro-solid-svg-icons/faCircleNotch";
import {useTranslation} from "react-i18next";

export interface ModalAlertProps {
  okLabel?: string;
  cancelLabel?: string;
  okIcon?: IconDefinition | null;
  cancelIcon?: IconDefinition | null;
  okVariant?: string;
  cancelVariant?: string;
  title?: string;
  confirmation: JSX.Element | string;
  show?: boolean;
  proceed?: (success: boolean) => void;
  enableEscape?: boolean;
  noOk?: boolean;
  noCancel?: boolean;
  closeTimeout?: number;
}

export default function ModalAlert(props: ModalAlertProps) {
  const {t} = useTranslation();
  const {
    okLabel = t('common.continue'),
    cancelLabel = t('common.cancel'),
    okIcon = faCheck,
    cancelIcon = faTimes,
    okVariant = 'primary',
    cancelVariant = 'dark',
    title = '',
    confirmation,
    show = true,
    proceed = () => {},
    enableEscape = false,
    noOk = false,
    noCancel = false,
    closeTimeout = 0,
  } = props;

  const [timeLeft, setTimeLeft] = useState<number>(closeTimeout);

  useInterval(() => {
    setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
  }, timeLeft > 0 ? 1000 : null);

  const header = title ? (
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
  ) : null;

  return (
    <div className="static-modal">
      <Modal
        show={show}
        onHide={() => proceed(false)}
        backdrop={enableEscape ? true : 'static'}
        keyboard={enableEscape}
        className="pb-3"
      >
        {header}
        <Modal.Body className="py-1">
          {confirmation}
        </Modal.Body>
        <Modal.Footer className="pt-3 wide-buttons">
          {!noCancel && (
            <button
              className={'btn btn-' + cancelVariant}
              onClick={() => proceed(false)}
              disabled={timeLeft !== 0}
            >
              {timeLeft !== 0 && (<FontAwesomeIcon icon={faCircleNotch} className="me-2" spin/>)}
              {timeLeft === 0 && cancelIcon && (<FontAwesomeIcon icon={cancelIcon} className="me-2"/>)}
              {cancelLabel}
            </button>
          )}
          {!noOk && (
            <button
              className={'btn ms-2 btn-' + okVariant}
              onClick={() => proceed(true)}
              disabled={timeLeft !== 0}
            >
              {okLabel}
              {timeLeft !== 0 && (<FontAwesomeIcon icon={faCircleNotch} className="ms-2 float-end" spin/>)}
              {timeLeft === 0 && okIcon && (<FontAwesomeIcon icon={okIcon} className="ms-2 float-end"/>)}
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
