import React, {useEffect, useState} from "react";
import {Routes, Route} from "react-router-dom";
import {useMoralis} from "react-moralis";
import Home from "./components/pages/Home";
import AccountPage from "./components/pages/AccountPage";
import AccountStore, {loggedInAccountAtom} from "@stores/accountStore";
import Preloader from "@components/common/Preloader";
import {Toaster} from "react-hot-toast";
import BankStore from "@stores/bankStore";
import {useRecoilState} from "recoil";
import BeesStore from "@stores/beesStore";
import {CookiesProvider} from "react-cookie";
import Moralis from "moralis";
import useChain from "@hooks/useChain";
import {useTranslation} from "react-i18next";
import logo from "@assets/images/logo.png";

function App() {
  const {t} = useTranslation();
  const { isWeb3Enabled, enableWeb3, isWeb3EnableLoading, isInitialized, isAuthenticated, account, logout, web3,  } = useMoralis();
  const [loggedInAccount, setLoggedInAccount] = useRecoilState(loggedInAccountAtom);
  const [lastAccount, setLastAccount] = useState<string | null>(null);
  const [chainId, setChainId] = useState<string | null>(null);
  const { switchNetwork } = useChain();

  const isLoggedIn = isAuthenticated && account && loggedInAccount && loggedInAccount === account;
  const isCurrentChainId = chainId === process.env.REACT_APP_CHAIN_ID;

  useEffect(() => {
    const connectorId = window.localStorage.getItem('connectorId');
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading) {
      // @ts-ignore
      enableWeb3({ provider: connectorId, chainId: process.env.REACT_APP_CHAIN_ID });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isWeb3Enabled]);

  useEffect(() => {
    if (isAuthenticated && loggedInAccount && account && lastAccount && lastAccount !== account) {
      logout();
      setLoggedInAccount(null);
      setLastAccount(null);
      window.localStorage.removeItem('connectorId');
    }
  }, [logout, lastAccount, setLoggedInAccount, isAuthenticated, loggedInAccount, account]);

  useEffect(() => {
    if (isAuthenticated && !loggedInAccount && account && !lastAccount) {
      setLoggedInAccount(account);
      setLastAccount(account);
    }
  }, [lastAccount, setLoggedInAccount, isAuthenticated, loggedInAccount, account]);

  useEffect(() => {
    if (window.location.hash === '#clearmydirtybrowser') {
      localStorage.clear();
    }
  }, []);

  useEffect(() => {
    Moralis.onChainChanged(function (chain) {
      setChainId(chain);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    setChainId(web3?.network?.chainId?.toString() || null);
  });

  if(isInitialized) {
    return (
      <React.Suspense fallback={<Preloader/>}>
        <CookiesProvider>
          {(chainId === null || isCurrentChainId) && (
            <>
              <AccountStore/>
              <BeesStore/>
              <BankStore/>
              <Routes>
                {!isLoggedIn && (<Route path="/:refId" element={<Home/>}/>)}
                {!isLoggedIn && (<Route path="/" element={<Home/>}/>)}
                {isLoggedIn && (
                  <>
                    <Route path="/:page" element={<AccountPage/>}/>
                    <Route path="/" element={<AccountPage/>}/>
                  </>
                )}
              </Routes>
            </>
          )}
          {chainId !== null && !isCurrentChainId && (
            <div id="home">
              <img src={logo} title="Honeypot Game" alt="Honeypot Game" width={200}/>

              <div className="mt-5 h3 px-2 tx-center">{t('error.wrong-network')}</div>
              <button
                className="btn btn-lg btn-primary mt-2 wd-250 wd-lg-auto"
                onClick={() => switchNetwork(`0x${(97).toString(16)}`)}
              >
                {t('common.switch-network')}
              </button>
            </div>
          )}
          <Toaster/>
        </CookiesProvider>
      </React.Suspense>
    )
  } else {
    return (<Preloader/>)
  }
}

export default App;
