import React, {useState, useTransition} from "react";
import Preloader from "@components/common/Preloader";
import FormattedNumber from "@components/common/FormattedNumber";
import {useRecoilValue} from "recoil";
import {add, convertStringToNumber, greaterThan, multiply, smallerThanOrEqual, subtract} from "@helpers/bignumber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/pro-solid-svg-icons/faCircleNotch";
import {faShoppingCart} from "@fortawesome/pro-duotone-svg-icons/faShoppingCart";
import {userBalanceSelector, useUpdateBalance} from "@stores/bankStore";
import {faExclamationTriangle} from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import {faMinus} from "@fortawesome/pro-solid-svg-icons/faMinus";
import {faPlus} from "@fortawesome/pro-solid-svg-icons/faPlus";
import config from "config.json";
import confirmAlert from "@components/ConfirmAlert";
import {buySlots} from "@contracts/HoneyGame";
import {toast} from "react-hot-toast";
import {useFetchApiary} from "@stores/beesStore";
import {useTranslation} from "react-i18next";

const SlotsShop = () => {
  const {t} = useTranslation();
  const [isPending] = useTransition();
  const balance = useRecoilValue(userBalanceSelector);
  const [count, setCount] = useState<string>('0');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const updateBalance = useUpdateBalance();
  const fetchApiary = useFetchApiary();

  const totalSlots = multiply(count, 10);
  const total = multiply(totalSlots, config.slotPrice || 0);
  const exceedBalance = greaterThan(total, balance || '0');

  const addCount = () => {
    setCount(add(count, 1));
  };

  const subtractCount = () => {
    setCount(count !== '0' ? subtract(count, 1) : '0');
  };

  const resetForm = () => {
    setCount('0');
  }

  const handleSubmit = () => {
    if (!count || count === '0') {
      toast.error(t('error.select-hectares'), {duration: 5000});
      return;
    }

    if (exceedBalance) {
      toast.error(t('error.buy-tokens-in-bank'), {duration: 5000});
      return;
    }

    setIsLoading(true);

    confirmAlert({
      title: t('alert.confirm-buy'),
      confirmation: (
        <>
          <div className="tx-semibold">{t('common.summary')}:</div>
          <FormattedNumber
            value={totalSlots}
            suffix={`${t('common.hectares')}: `}
            suffixClass="tx-bold tx-capitalize"
            className={`d-block`}
          />
          <FormattedNumber
            value={total}
            postfix="HNY"
            postfixClass="tx-bold"
            className={`d-block`}
          />
        </>
      ),
      okLabel: t('common.buy'),
      okIcon: faShoppingCart,
    }).then((result) => {
      if (!!result) {
        try {
          const waitPromise = buySlots(convertStringToNumber(count));

          toast.promise(waitPromise, {
            loading: t('alert.processing'),
            success: t('alert.buy-hectares-complete').replace('%slots', totalSlots),
            error: t('error.default'),
          });

          waitPromise
            .then(() => {
              updateBalance();
              fetchApiary();
              setIsLoading(false);
              resetForm();
            })
            .catch(() => {
              setIsLoading(false);
            });
        } catch (e) {
          toast.error(t('error.default'), {duration: 5000});
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    })
  }

  return (
    <React.Suspense fallback={<Preloader/>}>
      <div className="wd-100p d-flex flex-column align-items-start justify-content-start pb-5 pt-3 overflow-auto flex-1">
        <div className="card wd-100p wd-md-300 wd-xl-500">
          <div className="card-title">
            <h2>{t('common.buy-hectares')}</h2>
          </div>

          <div className="card-body pt-0">
            <FormattedNumber
              suffix={`${t('common.price')}: `}
              value={multiply(config.slotPrice, 10)}
              postfix="HNY"
            />

            <div className="tx-bold mb-2">{t('hectares.how-much')}</div>

            <div className="d-flex flex-column flex-md-row align-items-md-center mb-3 mb-md-0">
              <div className="input-group input-group-sm mb-1 mb-md-0 wd-150 me-4">
                <button className="btn btn-outline-secondary" onClick={subtractCount} disabled={count === '0' || isLoading}>
                  <FontAwesomeIcon icon={faMinus}/>
                </button>
                <input
                  type="number"
                  className={`form-control tx-center ps-3 pe-1 ${exceedBalance ? 'is-invalid' : ''}`}
                  value={count}
                  readOnly
                />
                <button className="btn btn-outline-secondary" onClick={addCount} disabled={isLoading}>
                  <FontAwesomeIcon icon={faPlus}/>
                </button>
              </div>
              <div className="text-muted">{t('hectares.amount')}</div>
            </div>

            <div className="my-2 tx-bold tx-18">{t('hectares.you-get').replace('%slots', totalSlots)}</div>

            <FormattedNumber
              suffix={`${t('common.total')}: `}
              value={total}
              postfix="HNY"
              className={`${greaterThan(count, 0) && exceedBalance ? 'tx-danger' : ''}`}
            />
          </div>

          <div className="card-button">
            <button
              className={`btn ${exceedBalance ? 'btn-warning' : 'btn-primary'}`}
              disabled={isLoading || smallerThanOrEqual(convertStringToNumber(count), 0)}
              onClick={handleSubmit}
            >
              <FontAwesomeIcon
                icon={isLoading ? faCircleNotch : exceedBalance ? faExclamationTriangle : faShoppingCart}
                className="me-1"
                spin={isLoading}
              />
              {t('common.buy')}
            </button>
          </div>
        </div>
      </div>

      {(isPending) && <Preloader/>}
    </React.Suspense>
  )
}

export default React.memo(SlotsShop);
