import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useRecoilValue} from "recoil";
import {loggedInAccountAtom,} from "@stores/accountStore";
import {toast} from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {Modal} from "react-bootstrap";
import {faTimes} from "@fortawesome/pro-solid-svg-icons/faTimes";
import IconWithLoading from "@components/common/IconWithLoading";
import {faGifts} from "@fortawesome/pro-duotone-svg-icons/faGifts";
import {isWelcomeBoxAvailableSelector} from "@stores/boxesStore";
import {faBoxOpen} from "@fortawesome/pro-duotone-svg-icons/faBoxOpen";
import {getLastWin, openWelcomeBox, Prize, PrizeType} from "@contracts/HoneyBox";
import {beeImages, getNft, useFetchApiary} from "@stores/beesStore";
import Preloader from "@components/common/Preloader";
import FormattedNumber from "@components/common/FormattedNumber";
import config from "config.json";
import GameCard from "@pages/Storage/components/GameCard";

export default function WelcomeBox() {
  const {t} = useTranslation();
  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [prize, setPrize] = useState<Prize | null>(null);
  const userAddress = useRecoilValue(loggedInAccountAtom);
  const isAvailable = useRecoilValue(isWelcomeBoxAvailableSelector);
  const updateApiary = useFetchApiary();

  const resetForm = () => {
    setLoading(false);

    if (prize) {
      updateApiary();
    }
  };

  const handleClose = () => {
    resetForm();
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const defaultError = () => {
    toast.error(t('error.default'), {duration: 5000});
    setLoading(false);
  }

  const openBox = () => {
    if (!userAddress) {
      return;
    }

    setLoading(true);

    try {
      const waitPromise = openWelcomeBox();

      waitPromise
        .then(() => {
          getLastWin(userAddress)
            .then((_prize) => {
              setPrize(_prize);
              setLoading(false);
            })
            .catch(() => {
              defaultError();
            });
        })
        .catch(() => {
          defaultError();
        });
    } catch (e) {
      defaultError();
    }
  };

  return (
    <>
      {isAvailable && (
        <button
          className="btn btn-link tx-danger tx-20 p-0 text-decoration-none me-3"
          onClick={handleShow}
          disabled={isLoading}
          id="welcome-box-button"
        >
          <FontAwesomeIcon icon={faGifts}/>
        </button>
      )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="pb-3" closeButton>
          <Modal.Title>
            {t('welcome.title')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          {!prize && !isLoading && t('welcome.description')}
          {!prize && isLoading && <Preloader inline={true}/>}
          {prize && !isLoading && (
            <div>
              <div className="tx-bold tx-20 mb-2">{t('welcome.you-won')}</div>
              {prize.prizeType === PrizeType.SLOTS && (
                <FormattedNumber
                  suffix={`${t('common.hectares')}: `}
                  value={prize.value}
                  className="d-block tx-bold tx-18"
                  suffixClass="tx-normal tx-capitalize"
                />
              )}
              {prize.prizeType === PrizeType.TOKENS && (
                <FormattedNumber
                  value={prize.value}
                  postfix="HNY"
                  className="d-block tx-bold tx-20"
                  suffixClass="tx-normal tx-capitalize"
                />
              )}
              {prize.prizeType === PrizeType.BEE && (
                <div className="tx-center">
                  <div className="tx-bold tx-20">
                    {config.bees[prize.value - 1].name}
                  </div>
                  <img
                    src={beeImages[prize.value - 1]}
                    title={config.bees[prize.value - 1].name}
                    alt={config.bees[prize.value - 1].name}
                    className="wd-200 mx-auto"
                  />
                  <FormattedNumber
                    suffix="+"
                    value={config.bees[prize.value - 1].maxDailyProfit}
                    postfix={t('common.per-day')}
                    postfixClass="tx-bold"
                    className="d-block mt-2"
                  />
                </div>
              )}
              {prize.prizeType === PrizeType.NFT && (
                <GameCard
                  item={getNft(prize.value)!}
                  itemSet={config.sets.find((_set) => _set.setId === getNft(prize.value)!.itemSet)!}
                  beeName={config.bees[getNft(prize.value)!.beeId - 1].name}
                />
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="pt-3 wide-buttons">
          <button className="btn btn-dark" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} className="me-2"/>
            {t('common.close')}
          </button>

          {!prize && (
            <button className="btn btn-primary ms-3" onClick={openBox} disabled={isLoading}>
              {t('common.open')}
              <IconWithLoading icon={faBoxOpen} className="ms-2 float-end" isLoading={isLoading}/>
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
