import React, {useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useRecoilValue} from "recoil";
import {
  loggedInAccountAtom, userDataSelector,
} from "@stores/accountStore";
import {Modal} from "react-bootstrap";
import {faTimes} from "@fortawesome/pro-solid-svg-icons/faTimes";
import IconWithLoading from "@components/common/IconWithLoading";
import {faList} from "@fortawesome/pro-solid-svg-icons/faList";
import CopyButton from "@components/common/CopyButton";
import {useTranslation} from "react-i18next";

export default function ShowAliasesModal() {
  const {t} = useTranslation();
  const [show, setShow] = useState(false);
  const userAddress = useRecoilValue(loggedInAccountAtom);
  const userData = useRecoilValue(userDataSelector);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const referralLink = `https://honeypot.game/?ref=${userAddress}`;

  return (
    <>
      <button
        className="btn btn-primary mb-2 me-md-2"
        onClick={handleShow}
        disabled={!userData}
      >
        <IconWithLoading icon={faList} className="me-2" isLoading={!userData}/>
        {t('bonus.show-aliases')}
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="pb-3" closeButton>
          <Modal.Title>
            {t('bonus.list-aliases')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div className="tx-bold mb-1">{t('bonus.default-link')}:</div>
          <CopyButton
            text={referralLink}
            element={referralLink}
            className="btn btn-link px-0 d-block wd-100p tx-dark tx-left text-decoration-none word-break"
          />

          {userData && userData.accountAliases.length > 0 && (
            <>
              <div className="tx-bold mt-4 mb-1">{t('common.aliases')}:</div>

              {userData.accountAliases.map((alias) => {
                const referralAlias = `https://honeypot.game/?ref=${alias}`;
                return (
                  <CopyButton
                    key={alias}
                    text={referralAlias}
                    element={referralAlias}
                    className="btn btn-link px-0 d-block wd-100p tx-dark tx-left text-decoration-none word-break mb-2"
                  />
                )
              })}
            </>
          )}

        </Modal.Body>
        <Modal.Footer className="pt-3">
          <button className="btn btn-dark" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} className="me-2"/>
            {t('common.close')}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
