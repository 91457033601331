import {atom, selector, useRecoilState} from "recoil";
import {apiaryRawSelector, isRegisteredSelector} from "@stores/beesStore";
import {getOwner, getUser} from "@contracts/HoneyGame";
import {availableProfit, calculateProfit} from "@contracts/ApiaryLand";
import {convertStringToNumber, multiply} from "@helpers/bignumber";
import config from "config.json";
import {useCallback, useEffect} from "react";
import {useCookies} from "react-cookie";
import {bigIntToNumber} from "@helpers/numbers";

export const refAtom = atom<string | null>({
  key: 'refId',
  default: localStorage.getItem('referral'),
});

export const loggedInAccountAtom = atom<string | null>({
  key: 'loggedInAccountAtom',
  default: null,
});

export const refState = selector<string | null>({
  key: 'refState',
  get: ({get}) => {
    return get(refAtom);
  },
  set: ({set}, newValue) => {
    set(refAtom, newValue);
    localStorage.setItem('referral', newValue ? newValue as string : '');
  },
});

export const ownerSelector = selector<string | null>({
  key: 'ownerSelector',
  get: async () => {
    return await getOwner();
  },
});

export const profitUpdateIdentifier = atom<number>({
  key: 'profitUpdateIdentifier',
  default: 0,
});

export const rawProfitSelector = selector<number | null>({
  key: 'rawProfitSelector',
  get: async ({get}) => {
    get(profitUpdateIdentifier);
    const apiary = get(apiaryRawSelector);

    if (!apiary) {
      return null;
    }

    const profit = multiply(await calculateProfit(
      apiary.bees.map((b) => b.toNumber()),
      apiary.items.map((i) => i.toNumber()),
    ), 2);

    return convertStringToNumber(profit);
  },
});

export const profitSelector = selector<number[] | null>({
  key: 'profitSelector',
  get: async ({get}) => {
    const profit = get(rawProfitSelector);
    const dayMultiplier = 24 * 3600;

    if (!profit) {
      return null;
    }

    return [
      multiply(profit, 365 * dayMultiplier),
      multiply(profit, 30 * dayMultiplier),
      multiply(profit, 7 * dayMultiplier),
      multiply(profit, dayMultiplier),
    ].map((v) => convertStringToNumber(v));
  },
});

export const availableProfitSelector = selector<string | null>({
  key: 'availableProfitSelector',
  get: async ({get}) => {
    const apiary = get(apiaryRawSelector);
    const userAddress = get(loggedInAccountAtom);

    if (!apiary || !userAddress) {
      return null;
    }

    return await availableProfit(userAddress, apiary.bees.map((b) => b.toNumber()), apiary.items.map((i) => i.toNumber()));
  },
});

interface ReferralLine {
  line: number;
  percents: number;
  partners: number;
  missedRewards: number;
  earnedRewards: number;
}

interface UserData {
  referralCount: number;
  rewards: number;
  missedRewards: number;
  partnerLevel: number;
  lines: ReferralLine[];
  accountAliases: string[];
}

export const userDataUpdateIdentifier = atom<number>({
  key: 'userDataUpdateIdentifier',
  default: 0,
});

export const userDataSelector = selector<UserData | null>({
  key: 'userDataSelector',
  get: async ({get}) => {
    get(userDataUpdateIdentifier);
    const userAddress = get(loggedInAccountAtom);
    const isRegistered = get(isRegisteredSelector);

    if (!userAddress || !isRegistered) {
      return null;
    }

    const result = await getUser(userAddress);

    return {
      referralCount: result.partnerCount.map((n) => bigIntToNumber(n, false)).reduce((x, y) => x + y),
      rewards: result.partnerEarnReward.map((n) => bigIntToNumber(n)).reduce((x, y) => x + y),
      missedRewards: result.partnerMissedReward.map((n) => bigIntToNumber(n)).reduce((x, y) => x + y),
      partnerLevel: result.partnerLevel.toNumber(),
      accountAliases: result.accountAliases,
      lines: config.partnerRewardPercents.map((p, index) => {
        return {
          line: index + 1,
          percents: p,
          partners: bigIntToNumber(result.partnerCount[index], false),
          earnedRewards: bigIntToNumber(result.partnerEarnReward[index]),
          missedRewards: bigIntToNumber(result.partnerMissedReward[index]),
        };
      }),
    };
  },
});

export default function AccountStore() {
  const [ref, setRef] = useRecoilState(refState);
  const [cookies] = useCookies(['ref']);

  useEffect(() => {
    if (cookies && cookies.ref && cookies.ref !== ref) {
      setRef(cookies.ref);
    }
  }, [cookies, ref, setRef]);

  return (<></>);
}

export const useUpdateUserData = () => {
  const [shouldUpdateUserData, setShouldUpdateUserData] = useRecoilState(userDataUpdateIdentifier);

  return useCallback(() => {
    setShouldUpdateUserData(shouldUpdateUserData + 1);
  }, [shouldUpdateUserData, setShouldUpdateUserData]);
}

export const useUpdateAvailableProfit = () => {
  const [shouldUpdateAvailableProfit, setShouldUpdateAvailableProfit] = useRecoilState(profitUpdateIdentifier);

  return useCallback(() => {
    setShouldUpdateAvailableProfit(shouldUpdateAvailableProfit + 1);
  }, [shouldUpdateAvailableProfit, setShouldUpdateAvailableProfit]);
}
