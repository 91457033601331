import {convertNumberToString, convertStringToNumber, subtract} from "@helpers/bignumber";
import BigNumber from "bignumber.js";
import Moralis from "moralis";

export const randomNumber = () => Math.floor(100000000 + Math.random() * 900000000000000);

export const round = (num: string | number | BigNumber, decimals: number) => {
  const factor = 10 ** decimals;
  return Math.round(convertStringToNumber(num + '') * factor) / factor;
};

export const repairPrecision = (num: number) => round(num, 10);

export const roundUp = (num: string | number | BigNumber, decimals: number) => {
  num = repairPrecision(convertStringToNumber(num + ''));
  const factor = 10 ** decimals;
  const magnified = repairPrecision(num * factor);
  return Math.ceil(magnified) / factor;
};

export const roundDown = (num: string | number | BigNumber, decimals: number) => {
  num = repairPrecision(convertStringToNumber(num + ''));
  const factor = 10 ** decimals;
  const magnified = repairPrecision(num * factor);
  return Math.floor(magnified) / factor;
};

export const numberWithCommas = (x: number | string, floor: boolean | undefined, decimals: number) => {
  let value = round(x, decimals);

  if (floor === true) {
    value = roundDown(x, decimals);
  } else if (floor === false) {
    value = roundUp(x, decimals);
  }

  const integer = parseInt(convertNumberToString(value));
  const digits = subtract(parseFloat(convertNumberToString(value)), integer);
  const result = integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return result + digits.toString().replace('0', '');
}

export const bigIntToNumber = (num: BigInt, fromWei = true) => {
  const n = fromWei ? Moralis.Units.FromWei(num.toString()) : num.toString();
  return convertStringToNumber(n);
}
