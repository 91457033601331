import {selector} from "recoil";
import {isWelcomeBoxAvailable} from "@contracts/HoneyBox";
import {apiaryRawSelector} from "@stores/beesStore";
import {loggedInAccountAtom} from "@stores/accountStore";

export const isWelcomeBoxAvailableSelector = selector<boolean | null>({
  key: 'isWelcomeBoxAvailableSelector',
  get: async ({get}) => {
    const apiary = get(apiaryRawSelector);
    const userAddress = get(loggedInAccountAtom);

    if (!apiary || !userAddress) {
      return null;
    }

    return await isWelcomeBoxAvailable(userAddress);
  },
});
