import Moralis from "moralis";
import contract from './ApiaryLand.json';
import BigNumber from "bignumber.js";

export const ApiaryLandContract = {
  contractAddress: process.env.REACT_APP_APIARY_CONTRACT_ADDRESS as string,
  abi: contract.abi,
};

export interface ApiaryRaw {
  owner: string;
  slots: BigNumber;
  bees: BigNumber[];
  items: BigNumber[];
  workStartTime: BigNumber;
  lastClaimTimestamp: BigNumber;
  lastDeferredPayoutTimestamp: BigNumber;
  deferredProfit: BigNumber;
  totalClaimedProfit: BigNumber;
}

export async function getApiary(userAddress: string):Promise<ApiaryRaw> {
  let resp = await Moralis.executeFunction({
    functionName: 'getApiary',
    params: {
      owner: userAddress,
    },
    ...ApiaryLandContract
  });

  return resp as unknown as ApiaryRaw;
}

export async function getUsedSlots(userAddress: string):Promise<number> {
  let resp = await Moralis.executeFunction({
    functionName: 'getUsedSlots',
    params: {
      owner: userAddress,
    },
    ...ApiaryLandContract
  });

  return (resp as unknown as BigNumber).toNumber();
}

export async function calculateProfit(bees: number[], items: number[]):Promise<string> {
  let resp = await Moralis.executeFunction({
    functionName: 'calcPureProfit',
    params: {
      bees,
      items,
      period: 1,
    },
    ...ApiaryLandContract
  });

  return Moralis.Units.FromWei(resp as unknown as number);
}

export async function availableProfit(owner: string, bees: number[], items: number[]):Promise<string> {
  let resp = await Moralis.executeFunction({
    functionName: 'calcAvailableProfitForClaiming',
    params: {
      owner,
      bees,
      items,
    },
    ...ApiaryLandContract
  });

  return Moralis.Units.FromWei(resp as unknown as number);
}

export async function getApiaryMood(owner: string):Promise<number> {
  let resp = await Moralis.executeFunction({
    functionName: 'getApiaryMood',
    params: {
      owner,
    },
    ...ApiaryLandContract
  });

  return (resp as unknown as BigNumber).toNumber();
}
