import {useCallback} from "react";
import {atom, selector, useRecoilState, useRecoilValue} from "recoil";
import {convertStringToNumber, divide, greaterThanOrEqual} from "@helpers/bignumber";
import {loggedInAccountAtom} from "@stores/accountStore";
import config from "config.json";
import {getBalance} from "@contracts/HoneyToken";
import {allowance, getStableBalance} from "@contracts/Stable";

export const balanceUpdateIdentifier = atom<number>({
  key: 'balanceUpdateIdentifier',
  default: 0,
});

export const exchangeRateSelector = selector<number>({
  key: 'exchangeRateSelector',
  get: () => {
    return convertStringToNumber(divide(config.swapRate, 10000));
  },
});

export const userBalanceSelector = selector<number>({
  key: 'userBalanceSelector',
  get: async ({get}) => {
    get(balanceUpdateIdentifier);
    const userAddress = get(loggedInAccountAtom);

    if (!userAddress) {
      return 0;
    }

    return await getBalance(userAddress);
  },
});

export const allowanceSelector = selector<number>({
  key: 'allowanceSelector',
  get: async ({get}) => {
    get(balanceUpdateIdentifier);
    const userAddress = get(loggedInAccountAtom);

    if (!userAddress) {
      return 0;
    }

    return await allowance(userAddress);
  },
});

export const usdtBalanceSelector = selector<number>({
  key: 'usdtBalanceSelector',
  get: async ({get}) => {
    get(balanceUpdateIdentifier);
    get(allowanceSelector);
    const userAddress = get(loggedInAccountAtom);

    if (!userAddress) {
      return 0;
    }

    return await getStableBalance(userAddress);
  },
});

export function useIsBalanceEnough() {
  const balance = useRecoilValue(userBalanceSelector);

  return useCallback((value: number | string, message = 'Not enough BNB on your wallet to complete transaction') => {
    const isEnough = greaterThanOrEqual(convertStringToNumber(balance), convertStringToNumber(value));

    // if (!isEnough) {
    //   toast.error(message, {duration: 5000});
    // }

    return isEnough;
  }, [balance]);
}

export default function BankStore() {
  // const shouldUpdateBalance = useRecoilValue(balanceUpdateIdentifier);
  // const setUsdtBalance = useSetRecoilState(usdtBalanceSelector);
  // const { assets } = useERC20Balance();

  // useEffect(() => {
  //   if (assets && assets.length) {
  //     const usdt = assets.find((ass) => ass.token_address.toLowerCase() === process.env.REACT_APP_STABLE_CONTRACT_ADDRESS!.toLowerCase());
  //
  //     console.log({assets, usdt});
  //
  //     setUsdtBalance(convertStringToNumber(Moralis.Units.FromWei(usdt?.balance || 0, 18)));
  //   }
  // }, [shouldUpdateBalance, assets, setUsdtBalance]);

  return (<></>);
}

export const useUpdateBalance = () => {
  const [shouldUpdateBalance, setShouldUpdateBalance] = useRecoilState(balanceUpdateIdentifier);

  return useCallback(() => {
    setShouldUpdateBalance(shouldUpdateBalance + 1);
  }, [shouldUpdateBalance, setShouldUpdateBalance]);
}
