import React, {useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useRecoilValue} from "recoil";
import {
  loggedInAccountAtom, useUpdateUserData,
} from "@stores/accountStore";
import {toast} from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {useIsBalanceEnough, useUpdateBalance} from "@stores/bankStore";
import {Modal} from "react-bootstrap";
import FormattedNumber from "@components/common/FormattedNumber";
import {faExclamationTriangle} from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import {faCartPlus} from "@fortawesome/pro-solid-svg-icons/faCartPlus";
import {faTimes} from "@fortawesome/pro-solid-svg-icons/faTimes";
import {buyAlias, getRefAddress} from "@contracts/HoneyGame";
import IconWithLoading from "@components/common/IconWithLoading";
import config from "config.json";
import {PagePath} from "@components/Sidebar";
import {useNavigate} from "react-router-dom";
import {faPencil} from "@fortawesome/pro-solid-svg-icons/faPencil";
import {faPlus} from "@fortawesome/pro-solid-svg-icons/faPlus";

export default function BuyAliasModal() {
  const {t} = useTranslation();
  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [alias, setAlias] = useState<string>('');
  const userAddress = useRecoilValue(loggedInAccountAtom);
  const isEnough = useIsBalanceEnough();
  const updateBalance = useUpdateBalance();
  const navigate = useNavigate();
  const aliasRegEx = /^[a-z0-9]+$/i;
  const updateUserData = useUpdateUserData();

  const isEnoughTokens = isEnough(config.aliasPrice);

  const resetForm = () => {
    setLoading(false);
    setAlias('');
  };

  const handleClose = () => {
    resetForm();
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const handleNavigate = () => {
    handleClose();
    navigate(PagePath.HONEY_BANK);
  };

  const createAlias = () => {
    if (!userAddress) {
      return;
    }

    setLoading(true);

    try {
      const waitPromise = buyAlias(userAddress, alias);

      toast.promise(waitPromise, {
        loading: t('alert.processing'),
        success: t('alert.alias-complete').replace('%alias', alias),
        error: t('error.default'),
      });

      waitPromise
        .then(() => {
          updateBalance();
          updateUserData();
          setLoading(false);
          handleClose();
        })
        .catch(() => {
          setLoading(false);
        });
    } catch (e) {
      toast.error(t('error.default'), {duration: 5000});
      setLoading(false);
    }
  };

  const processAlias = () => {
    if (alias.length < 3 || alias.length > 20) {
      toast.error(t('error.alias-length'), {duration: 5000});
      return;
    }

    if (!aliasRegEx.test(alias)) {
      toast.error(t('error.alias-invalid'), {duration: 5000});
      return;
    }

    setLoading(true);

    try {
      getRefAddress(alias)
        .then((result) => {
          if (result && result === '0x0000000000000000000000000000000000000000') {
            createAlias();
          } else {
            toast.error(t('error.alias-already-used'), {duration: 5000});
            setLoading(false);
          }
        })
        .catch(() => {
          toast.error(t('error.default'), {duration: 5000});
          setLoading(false);
        });
    } catch (e) {
      toast.error(t('error.default'), {duration: 5000});
      setLoading(false);
    }
  };

  return (
    <>
      <button
        className="btn btn-primary mb-2"
        onClick={handleShow}
        disabled={isLoading}
      >
        <IconWithLoading icon={faPencil} className="me-2" isLoading={isLoading}/>
        {t('common.create-alias')}
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="pb-3" closeButton>
          <Modal.Title>
            {t('common.create-alias')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          <FormattedNumber
            suffix={`${t('bonus.alias-price')}: `}
            value={config.aliasPrice}
            postfix="HNY"
            className="d-block tx-bold"
          />

          {!isEnoughTokens && (
            <div className="tx-danger tx-bold tx-18 mt-2">
              <div>
                <FontAwesomeIcon icon={faExclamationTriangle} className="me-1"/>
                {t('error.not-enough-tokens')}
                <button className="btn btn-link tx-danger tx-bold ms-1 tx-18 py-0 px-0 mb-1" onClick={handleNavigate}>
                  {t('error.not-enough-tokens-link')}
                </button>
              </div>
            </div>
          )}

          {isEnoughTokens && (
            <div className="input-group mt-3 mb-0">
              <div className="input-group-text">
                {t('common.alias')}:
              </div>
              <input
                type="text"
                className="form-control tx-center"
                value={alias}
                onChange={(e) => setAlias(e.target.value)}
                disabled={isLoading}
              />
              <div className="input-group-text">
                <FontAwesomeIcon icon={faPencil}/>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="pt-3 wide-buttons">
          <button className="btn btn-dark" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} className="me-2"/>
            {t('common.close')}
          </button>

          {!isEnoughTokens && (
            <button className="btn btn-primary ms-3" onClick={handleNavigate}>
              {t('common.buy-tokens')}
              <FontAwesomeIcon icon={faCartPlus} className="ms-2 float-end"/>
            </button>
          )}

          {isEnoughTokens && (
            <button className="btn btn-primary ms-3" onClick={processAlias} disabled={isLoading}>
              {t('common.create-alias')}
              <IconWithLoading icon={faPlus} className="ms-2 float-end" isLoading={isLoading}/>
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
