import React, {
  useCallback,
  useMemo,
} from 'react';
import {useTranslation} from "react-i18next";
import {currentLanguage, Language} from "@stores/language";
import {useRecoilState} from "recoil";
import {Dropdown} from "react-bootstrap";
import Flag from 'react-world-flags';

export interface LanguageSwitcherButtonProperties {
  language: Language;
  onClick?: () => void;
}

export default function LanguageSwitcherButton({language, onClick}: LanguageSwitcherButtonProperties) {
  const {i18n} = useTranslation();
  const [activeLanguage, setActiveLanguage] = useRecoilState(currentLanguage);

  const changeLanguage = useCallback(() => {
    setActiveLanguage(language);
    i18n.changeLanguage(language);

    if (onClick) {
      onClick();
    }
  }, [i18n, setActiveLanguage, onClick, language]);

  const nameAndFlag = useMemo(() => {
    let name = '';
    let flag: string = language;

    switch (language) {
      case Language.EN:
        flag = 'us';
        name = 'English';
        break;
      case Language.RU:
        name = 'Русский';
        break;
      // case Language.TR:
      //   name = 'Türkçe';
      //   break;
      // case Language.FR:
      //   name = 'Français';
      //   break;
      // case Language.HI:
      //   flag = 'in';
      //   name = 'हिंदी';
      //   break;
      // case Language.TH:
      //   name = 'ไทย';
      //   break;
      // case Language.ZH:
      //   flag = 'cn';
      //   name = '中国人';
      //   break;
      // case Language.VI:
      //   name = 'Tiếng Việt';
      //   break;
    }

    return (
      <>
        <Flag code={flag} width="20" height="16" className="me-1"/>
        {name}
      </>
    );
  }, [language]);

  return (
    <Dropdown.Item
      eventKey={language}
      active={activeLanguage === language}
      onClick={changeLanguage}
    >
      {nameAndFlag}
    </Dropdown.Item>
  );
}
