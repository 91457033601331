import React, {useMemo} from 'react';
import LanguageSwitcherButton from "./LanguageSwitcherButton";
import {currentLanguage, Language} from "@stores/language";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faGlobe} from "@fortawesome/pro-solid-svg-icons/faGlobe";
import {Dropdown} from "react-bootstrap";
import {useRecoilValue} from "recoil";

export interface LanguageSwitcherProps {
  className?: string;
  onClick?: () => void;
}

export default function LanguageSwitcher({className, onClick}: LanguageSwitcherProps) {
  const language = useRecoilValue(currentLanguage);

  const fixedLang = useMemo(() => {
    let lang: string = language;

    switch (language) {
      // case Language.ZH:
      //   lang = 'CN';
      //   break;
      case Language.EN:
      case Language.RU:
      // case Language.FR:
      // case Language.HI:
      // case Language.TH:
      // case Language.VI:
        break;
      default:
        break;
    }

    return lang;
  }, [language]);

  return (
    <div className={'language-switcher d-block d-lg-inline-block ' + className}>
      <Dropdown>
        <Dropdown.Toggle
          id="dropdown-language"
          key="dropdown-language"
          variant="transparent"
          className="px-0 py-1 my-3 my-lg-0 text-uppercase"
        >
          <FontAwesomeIcon icon={faGlobe} className="me-2"/>
          {fixedLang}
        </Dropdown.Toggle>

        <Dropdown.Menu id="language-switcher-list" className="overflow-hidden rounded-10">
          {Object.entries(Language).map(([key, lang]) => (
            <LanguageSwitcherButton language={lang} key={key} onClick={onClick}/>
          ))}
        </Dropdown.Menu>
      </Dropdown>


    </div>
  );
}
