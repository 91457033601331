import React, {useEffect, useMemo, useState, useTransition} from "react";
import Preloader from "@components/common/Preloader";
import FormattedNumber from "@components/common/FormattedNumber";
import {useRecoilValue} from "recoil";
import {Bee, beeImages, BeeName, emptySlotsSelector, useFetchApiary} from "@stores/beesStore";
import {add, greaterThan, multiply} from "@helpers/bignumber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/pro-solid-svg-icons/faPlus";
import {faMinus} from "@fortawesome/pro-solid-svg-icons/faMinus";
import {faShoppingCart} from "@fortawesome/pro-duotone-svg-icons/faShoppingCart";
import {userBalanceSelector, useUpdateBalance} from "@stores/bankStore";
import {faExclamationTriangle} from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import {faTimes} from "@fortawesome/pro-solid-svg-icons/faTimes";
import IconWithLoading from "@components/common/IconWithLoading";
import config from 'config.json';
import {toast} from "react-hot-toast";
import {buyBees} from "@contracts/HoneyGame";
import {useTranslation} from "react-i18next";
import confirmAlert from "@components/ConfirmAlert";

interface ISelectedBees {
  bee: Bee;
  count: number;
}

const BeesShop = () => {
  const {t} = useTranslation();
  const [isPending] = useTransition();
  const emptySlots = useRecoilValue(emptySlotsSelector);
  const balance = useRecoilValue(userBalanceSelector);
  const [selectedBees, setSelectedBees] = useState<ISelectedBees[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const updateBalance = useUpdateBalance();
  const fetchApiary = useFetchApiary();

  const beesSummary = useMemo(() => {
    return {
      cost: selectedBees?.map((b) => multiply(b.count,  b.bee.price)).reduce((value, n) => add(value, n), '0'),
      baseDailyProfit: selectedBees?.map((b) => multiply(b.count,  b.bee.baseDailyProfit)).reduce((value, n) => add(value, n), '0'),
      slots: selectedBees?.map((b) => multiply(b.count,  b.bee.slots)).reduce((value, n) => add(value, n), '0'),
      count: selectedBees?.reduce((value, b) => add(value, b.count), '0'),
    }
  }, [selectedBees]);

  const isSelectedBees = !!selectedBees?.filter((b) => !!b.count).length;

  const exceedSlots = greaterThan(beesSummary.slots || 0, emptySlots || '0');
  const exceedBalance = greaterThan(beesSummary.cost || 0, balance || '0');

  const changeBeeCount = (index: number, value: number) => {
    setSelectedBees((prevState) => {
      if (prevState) {
        const newState = JSON.parse(JSON.stringify(prevState));
        const newCount  = prevState[index].count + value;
        newState[index].count = value && newCount >= 0 ? newCount : 0;
        return newState;
      }

      return null;
    });
  }

  const addBees = (index: number) => {
    changeBeeCount(index, 1);
  };

  const subtractBees = (index: number) => {
    changeBeeCount(index, -1);
  };

  const clearBees = (index: number) => {
    changeBeeCount(index, 0);
  };

  const resetForm = () => {
    selectedBees?.map((_s, index) => clearBees(index));
  };

  const handleBuy = () => {
    if (!isSelectedBees || !selectedBees) {
      toast.error(t('error.select-bees'), {duration: 7000});
      return;
    }

    if (exceedBalance) {
      toast.error(t('error.buy-tokens-in-bank'), {duration: 7000});
      return;
    }

    if (exceedSlots) {
      toast.error(t('error.buy-hectares'), {duration: 7000});
      return;
    }

    setIsLoading(true);

    confirmAlert({
      title: t('alert.confirm-buy'),
      confirmation: (
        <>
          <div className="tx-semibold">{t('common.summary')}:</div>
          <FormattedNumber
            value={beesSummary.cost}
            postfix="HNY"
            postfixClass="tx-bold"
            className={`d-block`}
          />
          <FormattedNumber
            value={beesSummary.slots}
            suffix={`${t('common.hectares')}: `}
            suffixClass="tx-bold tx-capitalize"
            className={`d-block`}
          />

          <div className="tx-semibold mt-2">{t('common.profit')}:</div>
          <FormattedNumber
            value={beesSummary.baseDailyProfit}
            postfix={t('common.per-day')}
            postfixClass="tx-bold"
            className="d-block"
          />
        </>
      ),
      okLabel: t('common.buy'),
      okIcon: faShoppingCart,
    }).then((result) => {
      if (!!result) {
        try {
          const waitPromise = buyBees(selectedBees.map((b) => b.bee.id), selectedBees.map((b) => b.count));

          toast.promise(waitPromise, {
            loading: t('alert.processing'),
            success: t('alert.buy-bees-complete').replace('%bees', beesSummary.count || '0'),
            error: t('error.default'),
          });

          waitPromise
            .then(() => {
              updateBalance();
              fetchApiary();
              setIsLoading(false);
              resetForm();
            })
            .catch(() => {
              setIsLoading(false);
            });
        } catch (e) {
          toast.error(t('error.default'), {duration: 5000});
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    })
    .catch(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (!selectedBees) {
      setSelectedBees(config.bees.map((bee) => {
        return {bee, count: 0};
      }));
    }
  }, [selectedBees]);

  return (
    <React.Suspense fallback={<Preloader/>}>
      <div className="d-flex flex-column flex-md-row flex-1 overflow-auto">
        <div className="d-flex flex-column justify-content-start overflow-auto wd-md-250-f wd-100p wd-md-250-">
          <div className="d-md-flex flex-md-column justify-content-start overflow-auto pt-3 pb-3 wd-1500 wd-md-250-f">
            {BeeName.map((name, i) => {
              const bee = config.bees[i];

              return (
                <div className={`card wd-200 tx-center d-inline-block mb-3 me-2 me-md-0`} key={`bee-list-${bee.name}-${i+1}`}>
                  <div className="card-title tx-bold">{bee.name}</div>

                  <div className="card-body pt-1 pb-4 mb-2">
                    <FormattedNumber value={bee.baseDailyProfit} postfix={t('common.per-day')} className="d-block tx-bold" postfixClass="tx-normal"/>
                    <FormattedNumber value={bee.price} postfix="HNY" className="d-block tx-bold" postfixClass="tx-normal"/>
                    <FormattedNumber value={bee.slots} suffix={`${t('common.hectares')}: `} className="d-block tx-bold" suffixClass="tx-normal tx-capitalize"/>
                  </div>

                  <div className="card-button">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => addBees(i)}
                      disabled={isLoading || !bee.baseDailyProfit}
                    >
                      <IconWithLoading isLoading={!bee.baseDailyProfit} icon={faPlus} />
                      {t('common.add')}
                    </button>
                  </div>
                  <img src={beeImages[i]} alt="bee" className="card-bee"/>
                </div>
              )
            })}
          </div>
        </div>
        <div className="wd-100p d-flex flex-column align-items-center justify-content-start pb-5 pt-3 overflow-auto flex-1">
          <div className="card wd-100p wd-md-450 wd-xl-600">
            <div className="card-title">
              <h2>{t('common.your-order')}</h2>
            </div>
            <div className="card-body pt-0">
              <div className="table-responsive">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{t('common.bee')}</th>
                      <th>{t('common.count')}</th>
                      <th className="wd-150-f tx-right">{t('common.total')}</th>
                      <th/>
                    </tr>
                  </thead>
                  <tbody>
                  {!isSelectedBees && (
                    <tr>
                      <td colSpan={4}>{t('alert.no-selected-bees')}</td>
                    </tr>
                  )}
                  {isSelectedBees && (
                    <>
                      {selectedBees.map((bee, i) => {
                        return !bee.count ? null : (
                          <tr key={`selected-bee-${bee.bee.name}`} className="valign-middle">
                            <td>{i + 1}</td>
                            <td>{bee.bee.name}</td>
                            <td>
                              <div className="input-group mb-0 wd-150">
                                <button className="btn btn-outline-secondary" onClick={() => subtractBees(i)} disabled={isLoading}>
                                  <FontAwesomeIcon icon={faMinus}/>
                                </button>
                                <input
                                  type="number"
                                  className="form-control tx-center ps-3 pe-1"
                                  value={bee.count}
                                  readOnly
                                />
                                <button className="btn btn-outline-secondary" onClick={() => addBees(i)} disabled={isLoading}>
                                  <FontAwesomeIcon icon={faPlus}/>
                                </button>
                              </div>
                            </td>
                            <td className="tx-right">
                              <FormattedNumber
                                value={multiply(bee.count, bee.bee.price)}
                                postfix="HNY"
                                postfixClass="tx-bold"
                                className="d-block"
                              />
                              <FormattedNumber
                                value={multiply(bee.count, bee.bee.slots)}
                                suffix={`${t('common.hectares')}: `}
                                suffixClass="tx-bold tx-capitalize"
                                className="d-block"
                              />
                              <FormattedNumber
                                value={multiply(bee.count, bee.bee.baseDailyProfit)}
                                postfix={t('common.per-day')}
                                postfixClass="tx-bold"
                                className="d-block mt-2"
                              />
                            </td>
                            <td className="tx-right">
                              <button
                                className="btn btn-sm btn-outline-danger wd-30 ht-30"
                                onClick={() => clearBees(i)}
                              >
                                <FontAwesomeIcon icon={faTimes}/>
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                    </>
                  )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan={4} className="tx-right border-bottom-0">
                        {isSelectedBees && (
                          <>
                            <div className="tx-semibold">{t('common.summary')}:</div>
                            <FormattedNumber
                              value={beesSummary.cost}
                              postfix="HNY"
                              postfixClass="tx-bold"
                              className={`d-block ${exceedBalance ? 'tx-danger' : ''}`}
                            />
                            <FormattedNumber
                              value={beesSummary.slots}
                              suffix={`${t('common.hectares')}: `}
                              suffixClass="tx-bold tx-capitalize"
                              className={`d-block ${exceedSlots ? 'tx-danger' : ''}`}
                            />

                            <div className="tx-semibold mt-2">{t('common.profit')}:</div>
                            <FormattedNumber
                              value={beesSummary.baseDailyProfit}
                              postfix={t('common.per-day')}
                              postfixClass="tx-bold"
                              className="d-block"
                            />
                          </>
                        )}
                      </td>
                      <td  className="border-bottom-0"/>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>

            <div className="card-button">
              <button
                className={`btn ${(exceedSlots || exceedBalance) ? 'btn-warning' : 'btn-primary'}`}
                disabled={isLoading || !selectedBees?.filter((b) => !!b.count).length}
                onClick={handleBuy}
              >
                <IconWithLoading
                  isLoading={isLoading}
                  icon={exceedSlots || exceedBalance ? faExclamationTriangle : faShoppingCart}
                />
                {t('common.buy')}
              </button>
            </div>
          </div>
        </div>
      </div>

      {(isPending) && <Preloader/>}
    </React.Suspense>
  )
}

export default React.memo(BeesShop);
