import React from "react";
import {toggleNav} from "@helpers/toggleNav";
import SignOut from "@components/common/SignOut";
import {getEllipsisTxt} from "@helpers/formatters";
import CopyButton from "@components/common/CopyButton";
import useCurrentPageTitle from "@hooks/useCurrentPageTitle";
import {useRecoilValue} from "recoil";
import {loggedInAccountAtom} from "@stores/accountStore";
import WelcomeBox from "@components/common/WelcomeBox";

export default function Header() {
  const userAddress = useRecoilValue(loggedInAccountAtom);
  const pageTitle = useCurrentPageTitle();

  return (
    <nav
      className="navbar navbar-light bg-transparent px-2 ps-lg-0 pe-lg-4 py-2 py-sm-4"
      id="header"
    >
      <div className="card wd-100p">
        <div className="card-body d-flex flex-row justify-content-end align-items-center p-3 p-md-4">
          <button
            onClick={toggleNav}
            className="navbar-toggler d-block d-lg-none ms-0 me-auto"
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div className="h3 mb-0 d-none d-md-block ms-0 me-auto">{pageTitle}</div>

          <div className="me-lg-4">
            <WelcomeBox/>
            <CopyButton
              text={userAddress || ""}
              element={getEllipsisTxt(userAddress || "", 4)}
              className="btn btn-link p-0 tx-left tx-dark text-decoration-none"
            />
          </div>

          <SignOut className="d-none d-lg-block" />
        </div>
      </div>
    </nav>
  );
}
