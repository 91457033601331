import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {NavPages} from "@components/Sidebar";

export default function useCurrentPageTitle() {
  const {t} = useTranslation();
  const location = useLocation();
  const page = NavPages.find((p) => location.pathname === p.path);

  return useMemo(() => {
    return page ? t(page.title) : '';
  }, [t, page]);
}
