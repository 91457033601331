import Moralis from "moralis";
import contract from './MockStable.json';
import {convertStringToNumber} from "@helpers/bignumber";
import contractPromise from "@helpers/contractPromise";

export const StableContract = {
  contractAddress: process.env.REACT_APP_STABLE_CONTRACT_ADDRESS as string,
  abi: contract.abi,
};

export async function allowance(owner: string, spender = process.env.REACT_APP_HONEY_BANK_CONTRACT_ADDRESS!):Promise<number> {
  let resp = await Moralis.executeFunction({
    functionName: 'allowance',
    params: {
      owner,
      spender,
    },
    ...StableContract
  });

  return convertStringToNumber(Moralis.Units.FromWei(resp as unknown as string));
}

export async function approve(amount: string, spender = process.env.REACT_APP_HONEY_BANK_CONTRACT_ADDRESS!):Promise<boolean> {
  return contractPromise('approve', {spender, amount}, StableContract);
}

export async function getStableBalance(account: string):Promise<number> {
  let resp = await Moralis.executeFunction({
    functionName: 'balanceOf',
    params: {
      account,
    },
    ...StableContract
  });

  return convertStringToNumber(Moralis.Units.FromWei(resp as unknown as string));
}
