import React from "react";
import {Link} from "react-router-dom";

export default function Footer() {
  return (
    <div id="footer" className="tx-center tx-12 px-2 pt-1 pb-1">
      <span className="me-1">&copy;</span>
      <Link to="/" className="tx-dark">
        honeypot.game
      </Link>
      <span className="m-1 me-2">{new Date().getFullYear()}.</span>
      <span>All Rights Reserved.</span>
    </div>
  )
}
