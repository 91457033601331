import React, {useEffect, useRef, useState, useTransition} from "react";
import Preloader from "@components/common/Preloader";
import FormattedNumber from "@components/common/FormattedNumber";
import {useRecoilValue} from "recoil";
import {
  add,
  convertStringToNumber,
  divide,
  greaterThan,
  multiply,
  smallerThanOrEqual,
} from "@helpers/bignumber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/pro-solid-svg-icons/faCircleNotch";
import {faShoppingCart} from "@fortawesome/pro-duotone-svg-icons/faShoppingCart";
import {userBalanceSelector, useUpdateBalance} from "@stores/bankStore";
import {faExclamationTriangle} from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import {faMinus} from "@fortawesome/pro-solid-svg-icons/faMinus";
import {faPlus} from "@fortawesome/pro-solid-svg-icons/faPlus";
import {getNft, Item, ItemsSet, useFetchApiary} from "@stores/beesStore";
import config from "config.json";
import Slider from "react-slick";
import {faChevronLeft} from "@fortawesome/pro-solid-svg-icons/faChevronLeft";
import {faChevronRight} from "@fortawesome/pro-solid-svg-icons/faChevronRight";
import {roundDown} from "@helpers/numbers";
import {Modal} from "react-bootstrap";
import {faTimes} from "@fortawesome/pro-solid-svg-icons/faTimes";
import {faCartPlus} from "@fortawesome/pro-solid-svg-icons/faCartPlus";
import IconWithLoading from "@components/common/IconWithLoading";
import {useNavigate} from "react-router-dom";
import {PagePath} from "@components/Sidebar";
import {toast} from "react-hot-toast";
import {buyItems} from "@contracts/HoneyGame";
import {useTranslation} from "react-i18next";
import GameCard from "@pages/Storage/components/GameCard";

interface ISelectedItem extends Item {
  count: number;
}

interface ISelectedSet extends ItemsSet {
  items: ISelectedItem[];
}

const ItemsShop = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [isPending] = useTransition();
  const balance = useRecoilValue(userBalanceSelector);
  const [selectedItems, setSelectedItems] = useState<ISelectedSet[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [show, setShow] = useState(false);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const updateBalance = useUpdateBalance();
  const fetchApiary = useFetchApiary();

  const allowedSets: ItemsSet[] = config.sets.filter((_set) => config.salableSets.indexOf(_set.setId) !== -1);

  const filteredItems = selectedItems.filter((_set) => _set.items.filter((_item) => !!_item.count));

  const total = filteredItems.reduce(
    (value, n) =>
      add(value, n.items.reduce(
        (itemsValue, item) => itemsValue + (item.count * item.price), 0),
      ), '0'
  );

  const isSelectedItems = !!filteredItems.length && !smallerThanOrEqual(convertStringToNumber(total), 0);
  const exceedBalance = greaterThan(total, balance || '0');

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleNavigate = () => navigate(PagePath.HONEY_BANK);

  const changeItemsCount = (setIndex: number, item: number, value: number) => {
    setSelectedItems((prevState) => {
      if (prevState) {
        const newState = JSON.parse(JSON.stringify(prevState));
        const _set = prevState[setIndex];
        const newCount  = _set.items[item].count + value;
        newState[setIndex].items[item].count = value && newCount >= 0 ? newCount : 0;
        return newState;
      }

      return null;
    });
  }

  const addItem = (setIndex: number, index: number) => {
    changeItemsCount(setIndex, index, 1);
  };

  const subtractItem = (setIndex: number, index: number) => {
    changeItemsCount(setIndex, index, -1);
  };

  const clearItems = (setIndex: number, index: number) => {
    changeItemsCount(setIndex, index, 0);
  };

  const resetForm = () => {
    filteredItems.forEach((_set, index) => {
      _set.items.forEach((_item, itemIndex) => {
        clearItems(index, itemIndex);
      });
    });
  };

  const handleBuy = () => {
    if (!total || !isSelectedItems) {
      toast.error(t('error.no-select-items'), {duration: 7000});
      return;
    }

    if (exceedBalance) {
      toast.error(t('error.buy-tokens-in-bank'), {duration: 7000});
      return;
    }

    setIsLoading(true);

    const sets = filteredItems.map((_set) => _set.items);
    const itemIds = sets.map((_set) => _set.filter((_item) => !!_item.count).map((_item) => _item.itemId));
    const itemAmounts = sets.map((_set) => _set.filter((_item) => !!_item.count).map((_item) => _item.count));
    const ids = itemIds.reduce((acc, _item) => acc.concat(_item), []);
    const amounts = itemAmounts.reduce((acc, _item) => acc.concat(_item), []);

    const waitPromise = buyItems(ids, amounts);

    toast.promise(waitPromise, {
      loading: t('alert.processing'),
      success: t('alert.buy-items-complete').replace('%items', amounts.reduce((acc, n) => acc + n, 0).toString()),
      error: t('error.default'),
    });

    waitPromise
      .then(() => {
        updateBalance();
        fetchApiary();
        setIsLoading(false);
        resetForm();
        handleClose();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!selectedItems.length) {
      setSelectedItems(allowedSets.map((_set) => ({
          ..._set,
          items: _set.items.map((item) => ({...item, count: 0})),
      })));
    }
  }, [selectedItems, allowedSets]);

  return (
    <React.Suspense fallback={<Preloader/>}>
      <div className="wd-100p d-flex flex-column align-items-center justify-content-start pb-5 pt-3 overflow-auto flex-1">

        <div className="border-top wd-100p pt-4 mb-3 d-flex justify-content-start align-items-start flex-wrap">
          {allowedSets.map((_set, setIndex) => (
            <button
              key={`shop-tab-switch-${_set.setId}`}
              className={`btn ${setIndex === currentTab ? 'btn-primary' : 'btn-outline-dark'} rounded-20 me-3 mb-3`}
              onClick={() => setCurrentTab(setIndex)}
            >
              <div>{_set.name}</div>
              <div className="tx-12 text-muted">+{_set.bonusPercents}%</div>
            </button>
          ))}

          <div className="ms-auto me-0 tx-right">
            <div className="mb-2">
              <FormattedNumber
                value={total}
                className={`tx-bold tx-18 ${exceedBalance ? 'tx-danger' : ''}`}
                suffix={`${t('common.total')}: `}
                postfix="HNY"
              />
            </div>
            <div>
              <button
                className={`btn ${exceedBalance ? 'btn-warning' : 'btn-primary'}`}
                disabled={isLoading || !isSelectedItems}
                onClick={handleShow}
              >
                <FontAwesomeIcon
                  icon={isLoading ? faCircleNotch : exceedBalance ? faExclamationTriangle : faShoppingCart}
                  className="me-1"
                  spin={isLoading}
                />
                {t('common.review-and-buy')}
              </button>
            </div>
          </div>
        </div>

        <div className="wd-100p mt-2 mt-lg-4 mb-4" id="nft-bees-wrapper">
          <div>
            {selectedItems.length !== 0 && selectedItems[currentTab].items.map((item, itemIndex) => {
              const nft = getNft(item.itemId);
              const bee = config.bees.find((bee) => bee.id === item.beeId);

              return (
                <div className="card tx-center" key={`storage-bee-${itemIndex}`}>
                  <div className="card-title tx-bold tx-20 px-2">
                    {bee?.name}
                  </div>

                  <div className="card-body pt-0 px-2">
                    <div
                      className="py-2"
                      key={`bee-${itemIndex}-item-${item.itemId}`}
                    >
                      <GameCard
                        item={nft!}
                        itemSet={config.sets.find((_set) => _set.setId === nft!.itemSet)!}
                        beeName={bee?.name || ''}
                      />

                      <div className="my-2">
                        <FormattedNumber
                          value={roundDown(divide(multiply(bee?.baseDailyProfit || 0, item.bonusPercents), 100), 2)}
                          suffix="+"
                          postfix={t('common.per-day')}
                          className="d-block text-muted tx-14"
                        />
                        <FormattedNumber
                          value={item.price}
                          suffix={`${t('common.price')}: `}
                          postfix="HNY"
                          className="d-block tx-14"
                        />
                      </div>
                      <div className="input-group mb-0 mx-auto">
                        <button
                          className="btn btn-outline-secondary"
                          onClick={() => subtractItem(currentTab, itemIndex)}
                          disabled={item.count === 0}
                        >
                          <FontAwesomeIcon icon={faMinus}/>
                        </button>
                        <input
                          type="number"
                          className="form-control tx-center ps-sm-3 pe-sm-1"
                          value={item.count}
                          readOnly
                        />
                        <button
                          className="btn btn-outline-secondary"
                          onClick={() => addItem(currentTab, itemIndex)}
                        >
                          <FontAwesomeIcon icon={faPlus}/>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header className="pb-3" closeButton>
          <Modal.Title>
            {t('common.buy-items')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          {exceedBalance && (
            <div className="tx-danger tx-bold tx-18 mt-2">
              <div>
                <FontAwesomeIcon icon={faExclamationTriangle} className="me-1"/>
                {t('error.not-enough-tokens')}
                <button className="btn btn-link tx-danger tx-bold ms-1 tx-18 py-0 px-0 mb-1" onClick={handleNavigate}>
                  {t('error.not-enough-tokens-link')}
                </button>
              </div>
            </div>
          )}

          <div className="table-responsive">
            <table className="table mb-0">
              <thead>
              <tr>
                <th className="d-none d-sm-block">#</th>
                <th>{t('common.item')}</th>
                <th>{t('common.count')}</th>
                <th className="wd-150-f tx-right">{t('common.total')}</th>
                <th/>
              </tr>
              </thead>
              <tbody>
              {!isSelectedItems && (
                <tr>
                  <td className="d-none d-sm-table-cell"/>
                  <td colSpan={3}>{t('alert.no-selected-items')}</td>
                </tr>
              )}
              {isSelectedItems && (
                <>
                  {selectedItems.map((_set, setIndex) => (
                    <React.Fragment key={`set-summary-${_set.setId}`}>
                      {_set.items.map((item, itemIndex) => {
                        const bee = config.bees.find((bee) => bee.id === item.beeId);
                        return !item.count ? null : (
                          <tr key={`selected-item-summary-${item.name}`} className="valign-middle">
                            <td className="d-none d-sm-table-cell">{itemIndex + 1}</td>
                            <td>
                              <div>{item.name}</div>
                              <FormattedNumber
                                value={item.bonusPercents}
                                suffix="+"
                                postfix="%"
                                postfixClass="tx-bold"
                                className="d-block"
                              />
                              <FormattedNumber
                                value={roundDown(divide(multiply(bee?.baseDailyProfit || 0, item.bonusPercents), 100), 2)}
                                suffix="+"
                                postfix={t('common.per-day')}
                                className="d-block text-muted tx-12"
                              />
                            </td>
                            <td>
                              <div className="input-group mb-0 wd-150">
                                <button className="btn btn-outline-secondary" onClick={() => subtractItem(setIndex, itemIndex)} disabled={isLoading}>
                                  <FontAwesomeIcon icon={faMinus}/>
                                </button>
                                <input
                                  type="number"
                                  className="form-control tx-center ps-sm-3 pe-3 pe-sm-1"
                                  value={item.count}
                                  readOnly
                                />
                                <button className="btn btn-outline-secondary" onClick={() => addItem(setIndex, itemIndex)} disabled={isLoading}>
                                  <FontAwesomeIcon icon={faPlus}/>
                                </button>
                              </div>
                            </td>
                            <td className="tx-right">
                              <FormattedNumber
                                value={multiply(item.count, item.price)}
                                postfix="HNY"
                                postfixClass="tx-bold"
                                className="d-block"
                              />
                            </td>
                            <td className="tx-right">
                              <button
                                className="btn btn-sm btn-outline-danger wd-30 ht-30"
                                onClick={() => clearItems(setIndex, itemIndex)}
                              >
                                <FontAwesomeIcon icon={faTimes}/>
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                    </React.Fragment>
                  ))}
                </>
              )}
              </tbody>
              <tfoot>
              <tr>
                <td className="d-none d-sm-table-cell border-bottom-0"/>
                <td colSpan={3} className="tx-right border-bottom-0">
                  {isSelectedItems && (
                    <>
                      <div className="tx-semibold">{t('common.summary')}:</div>
                      <FormattedNumber
                        value={total}
                        postfix="HNY"
                        postfixClass="tx-bold"
                        className={`d-block ${exceedBalance ? 'tx-danger' : ''}`}
                      />
                    </>
                  )}
                </td>
                <td  className="border-bottom-0"/>
              </tr>
              </tfoot>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer className="pt-3">
          <button className="btn btn-dark" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} className="me-2"/>
            {t('common.close')}
          </button>

          {exceedBalance && (
            <button className="btn btn-primary ms-3" onClick={handleNavigate}>
              <FontAwesomeIcon icon={faCartPlus} className="me-2"/>
              {t('common.buy-tokens')}
            </button>
          )}

          {!exceedBalance && (
            <button className="btn btn-primary ms-3" onClick={handleBuy} disabled={isLoading || !isSelectedItems}>
              <IconWithLoading icon={faShoppingCart} className="me-2" isLoading={isLoading}/>
              {t('common.buy-items')}
            </button>
          )}
        </Modal.Footer>
      </Modal>

      {(isPending) && <Preloader/>}
    </React.Suspense>
  )
}

export default React.memo(ItemsShop);
