import React, {useTransition} from "react";
import Preloader from "@components/common/Preloader";
import FormattedNumber from "@components/common/FormattedNumber";
import {useRecoilValue} from "recoil";
import useCurrentPageTitle from "@hooks/useCurrentPageTitle";
import {loggedInAccountAtom, userDataSelector} from "@stores/accountStore";
import {exchangeRateSelector} from "@stores/bankStore";
import {multiply} from "@helpers/bignumber";
import CopyButton from "@components/common/CopyButton";
import {useTranslation} from "react-i18next";
import QRCode from "qrcode.react";
import {isRegisteredSelector} from "@stores/beesStore";
import Register from "@pages/Register";
import BuyAliasModal from "@pages/Bonus/components/BuyAliasModal";
import ShowAliasesModal from "@pages/Bonus/components/ShowAliasesModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/pro-solid-svg-icons/faQuestionCircle";

export default function Bonus() {
  const {t} = useTranslation();
  const [isPending] = useTransition();
  const pageTitle = useCurrentPageTitle();
  const userData = useRecoilValue(userDataSelector);
  const rate = useRecoilValue(exchangeRateSelector);
  const userAddress = useRecoilValue(loggedInAccountAtom);
  const isRegistered = useRecoilValue(isRegisteredSelector);

  const refId = userData?.accountAliases.length ? userData.accountAliases[userData.accountAliases.length - 1] : userAddress;

  const referralLink = `https://honeypot.game/?ref=${refId}`;

  const cards = [
    {
      title: t('bonus.total-partners'),
      value: userData?.referralCount,
    },
    {
      title: t('bonus.total-earned'),
      value: userData?.rewards,
    },
    {
      title: t('bonus.total-missed'),
      value: userData?.missedRewards,
    },
  ];

  return (
    <React.Suspense fallback={<Preloader/>}>
      <div className="container-fluid pb-4 ps-lg-0 pe-lg-4">
        <div className="d-flex d-md-none align-items-center mb-4">
          <h1 className="mb-0">{pageTitle}</h1>
        </div>

        {!isRegistered ? (<Register/>) : (
          <>

            <div className="row">
              <div className="col-md-3 mb-4">
                <div className="card ht-100p tx-center">
                  <div className="card-title tx-bold tx-md-18">{t('bonus.opened-lines')}</div>
                  <div className="card-body pt-0">
                    <FormattedNumber value={userData?.partnerLevel} className="d-block tx-40"/>

                    <a
                      href="https://docs.honeypot.game/igrovye-mekhaniki/partnerskaya-programma#pravila-aktivacii-partnerskoi-programmy"
                      className="btn btn-sm btn-primary mt-1"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon icon={faQuestionCircle} className="me-2"/>
                      {t('bonus.how-to')}
                    </a>
                  </div>
                </div>
              </div>

              {cards.map((card, i) => (
                <div className="col-md-3 mb-4" key={`referral-data-${i}`}>
                  <div className="card ht-100p tx-center">
                    <div className="card-body d-flex flex-column align-items-center justify-content-center ht-100p">
                      <div className="tx-bold tx-md-18 pb-3">
                        {card.title}
                      </div>
                      <FormattedNumber
                        value={card.value}
                        postfix={i === 0 ? undefined : 'HNY'}
                        className="d-block tx-bold tx-24"
                        postfixClass="tx-normal"
                      />
                      {i !== 0 && (
                        <FormattedNumber
                          value={card.value !== undefined && rate ? multiply(card.value, rate) : null}
                          suffix="(~"
                          postfix="$)"
                          className="d-block text-muted tx-18"
                          postfixClass="tx-normal"
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="card wd-100p wd-xl-950 my-2 my-md-4">
              <div className="card-body d-flex flex-column flex-md-row">
                <div className="tx-center tx-md-left">
                  <QRCode value={referralLink} size={130}/>
                </div>
                <div className="ms-md-3 tx-center tx-md-left">
                  <div className="tx-bold tx-20">{t('bonus.invite-link')}</div>
                  <CopyButton
                    text={referralLink}
                    element={referralLink}
                    className="btn btn-link px-0 d-block wd-100p tx-dark tx-center tx-md-left tx-18 text-decoration-none word-break"
                    noIcon
                  />

                  <div className="mt-2">
                    <CopyButton text={referralLink} element={t('common.copy')} className="btn btn-primary me-md-2 mb-2"/>
                    <ShowAliasesModal/>
                    <BuyAliasModal/>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mt-3 mt-md-5">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-striped mb-0">
                    <thead>
                    <tr>
                      <th>{t('common.line')}</th>
                      <th>{t('common.percents')}</th>
                      <th>{t('common.partners')}</th>
                      <th>{t('common.missed')}</th>
                      <th>{t('common.earned')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {userData?.lines.map((line) => (
                      <tr key={`referral-data-line-${line.line}`}>
                        <td>{line.line}</td>
                        <td>{line.percents}%</td>
                        <td>{line.partners}</td>
                        <td><FormattedNumber value={line.missedRewards} postfix="HNY"/></td>
                        <td><FormattedNumber value={line.earnedRewards} postfix="HNY"/></td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      {(isPending) && <Preloader/>}
    </React.Suspense>
  )
}
