import Moralis from "moralis";
import contract from './HoneypotGame.json';
import contractPromise from "@helpers/contractPromise";
import BigNumber from "bignumber.js";

/* global BigInt */

interface UserRaw {
  partnerLevel: BigNumber;
  partnerEarnReward: BigInt[];
  partnerMissedReward: BigInt[];
  partnerCount: BigInt[];
  accountAliases: string[];
}

export const HoneypotGameContract = {
  contractAddress: process.env.REACT_APP_HONEYPOT_GAME_CONTRACT_ADDRESS as string,
  abi: contract.abi,
};

export async function createApiary(upline: string):Promise<boolean> {
  return contractPromise('register', {upline}, HoneypotGameContract);
}

export async function getRegistrationPrice():Promise<string> {
  let resp = await Moralis.executeFunction({
    functionName: 'getRegistrationPrice',
    ...HoneypotGameContract
  });

  return Moralis.Units.FromWei(resp as unknown as number);
}

export async function getOwner():Promise<string> {
  let resp = await Moralis.executeFunction({
    functionName: 'owner',
    ...HoneypotGameContract
  });

  return resp as unknown as string;
}

export async function getRefAddress(ref: string):Promise<string> {
  let resp = await Moralis.executeFunction({
    functionName: 'getAddressByAlias',
    params: {
      ref,
    },
    ...HoneypotGameContract
  });

  return resp as unknown as string;
}

export async function getUser(account: string):Promise<UserRaw> {
  let resp = await Moralis.executeFunction({
    functionName: 'getUser',
    params: {
      account,
    },
    ...HoneypotGameContract
  });

  return resp as unknown as UserRaw;
}

export async function buyBees(beeIds: number[], amounts: number[]):Promise<boolean> {
  return contractPromise('buyBees', {beeIds, amounts}, HoneypotGameContract);
}

export async function buySlots(packs: number):Promise<boolean> {
  return contractPromise('buySlotPacks', {packs}, HoneypotGameContract);
}

export async function claimProfit():Promise<boolean> {
  return contractPromise('claimProfit', {}, HoneypotGameContract);
}

export async function buyAlias(account: string, ref: string):Promise<boolean> {
  return contractPromise('buyAlias', {account, ref}, HoneypotGameContract);
}

export async function buyItems(itemIds: number[], amounts: number[]):Promise<boolean> {
  return contractPromise('buyItems', {itemIds, amounts}, HoneypotGameContract);
}

export async function setApiaryItems(itemIds: number[]):Promise<boolean> {
  return contractPromise('setApiaryItems', {itemIds}, HoneypotGameContract);
}
