import contract from './HoneyBank.json';
import contractPromise from "@helpers/contractPromise";

export const HoneyBankContract = {
  contractAddress: process.env.REACT_APP_HONEY_BANK_CONTRACT_ADDRESS as string,
  abi: contract.abi,
};

export async function buyTokens(tokensAmount: string):Promise<boolean> {
  return contractPromise('buyTokens', {tokensAmount}, HoneyBankContract);
}

export async function sellTokens(tokensAmount: string):Promise<boolean> {
  return contractPromise('sellTokens', {tokensAmount}, HoneyBankContract);
}
