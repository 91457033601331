import React, {useState, useTransition} from "react";
import Preloader from "@components/common/Preloader";
import FormattedNumber from "@components/common/FormattedNumber";
import {useRecoilValue} from "recoil";
import useCurrentPageTitle from "@hooks/useCurrentPageTitle";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDownToLine} from "@fortawesome/pro-duotone-svg-icons/faArrowDownToLine";
import {faRightLeft} from "@fortawesome/pro-duotone-svg-icons/faRightLeft";
import {
  allowanceSelector,
  exchangeRateSelector,
  usdtBalanceSelector,
  userBalanceSelector,
  useUpdateBalance
} from "@stores/bankStore";
import {
  add,
  convertNumberToString,
  convertStringToNumber,
  divide,
  greaterThan,
  multiply,
  smallerThanOrEqual,
  subtract
} from "@helpers/bignumber";
import {roundDown} from "@helpers/numbers";
import {buyTokens, sellTokens} from "@contracts/HoneyBank";
import IconWithLoading from "@components/common/IconWithLoading";
import Moralis from "moralis";
import {approve} from "@contracts/Stable";
import {toast} from "react-hot-toast";
import config from "config.json";
import {useTranslation} from "react-i18next";

export default function HoneyBank() {
  const {t} = useTranslation();
  const [isPending] = useTransition();
  const hnyBalance = useRecoilValue(userBalanceSelector);
  const usdtBalance = useRecoilValue(usdtBalanceSelector);
  const allowedAmount = useRecoilValue(allowanceSelector);
  const pageTitle = useCurrentPageTitle();
  const [isHnyTarget, setIsHnyTarget] = useState<boolean>(true);
  const [sourceValue, setSourceValue] = useState<string>('');
  const [targetValue, setTargetValue] = useState<string>('');
  const originalRate = useRecoilValue(exchangeRateSelector) || 0;
  const fee = divide(config.swapFeePercents, 100);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const updateBalance = useUpdateBalance();

  const sourceCurrency = !isHnyTarget ? 'HNY' : 'USDT';
  const targetCurrency = !isHnyTarget ? 'USDT' : 'HNY';
  const sourceBalance = !isHnyTarget ? hnyBalance : usdtBalance;
  const isSourceValueValid = sourceValue !== '' && greaterThan(sourceValue, 0) && smallerThanOrEqual(convertStringToNumber(sourceValue), sourceBalance)
    ? true
    : sourceValue !== '' ? false : null;

  const buyRate = multiply(originalRate, add(1, fee));
  const sellRate = multiply(originalRate, subtract(1, fee));
  const rate = isHnyTarget ? divide(1, buyRate) : sellRate;

  const calculateTarget = (value: string) => {
    const originalTarget = multiply(value, rate);
    return convertNumberToString(roundDown(originalTarget, 6));
  }

  const handleSourceChange = (value: string, _roundDown = false) => {
    const _value = _roundDown ? convertNumberToString(roundDown(value, 2)) : value;
    setSourceValue(_value);
    setTargetValue(calculateTarget(_value));
  };

  const handleTargetChange = (value: string) => {
    setTargetValue(value);
    setSourceValue(roundDown(divide(value, rate), 2) + '');
  };

  const handleSwap = () => {
    setIsHnyTarget((prevState) => !prevState);
    handleSourceChange('');
  };

  const defaultError = () => {
    toast.error(t('error.default'), {duration: 5000});
  };

  const handleSubmit = () => {
    if (!isSourceValueValid) {
      toast.error(t('error.invalid-amount'), {duration: 5000});
      return;
    }

    setIsLoading(true);

    try {
      const submitFunction = targetCurrency === "USDT" ? sellTokens : buyTokens;
      submitFunction(Moralis.Units.ETH(targetCurrency === "USDT" ? sourceValue : targetValue))
        .then(() => {
          setIsLoading(false);
          handleSourceChange('');
          updateBalance();
        })
        .catch(() => {
          setIsLoading(false);
          updateBalance();
          defaultError();
        });
    } catch (e) {
      setIsLoading(false);
      defaultError();
    }
  }

  const handleAllowance = async () => {
    setIsLoading(true);

    try {
      approve(Moralis.Units.ETH(100000000)).then(() => {
        updateBalance();
        setIsLoading(false);
      }).catch(() => {
        setIsLoading(false);
        defaultError();
      });
    } catch (e) {
      setIsLoading(false);
      defaultError();
    }
  };

  return (
    <React.Suspense fallback={<Preloader/>}>
      <div className="container-fluid pb-4 ps-lg-0 pe-lg-4">
        <div className="d-flex d-md-none align-items-center mb-4">
          <h1 className="mb-0">{pageTitle}</h1>
        </div>

        <div className="card d-flex flex-column justify-content-between align-items-center wd-100p wd-lg-500 pos-relative overflow-hidden">
          <div className="row wd-100p p-3">
            <div className="col-md-6">
              <div className="tx-bold">{t('bank.buy-rate')}:</div>
              <div>{buyRate} USDT</div>
            </div>
            <div className="col-md-6 tx-md-right">
              <div className="tx-bold">{t('bank.sell-rate')}:</div>
              <div>{sellRate} USDT</div>
            </div>
          </div>
          <div className="wd-100p">
            <div className="card-title pb-0 h1 tx-bold">{sourceCurrency}</div>
            <div className="card-body pt-0">
              <input
                type="number"
                className={
                  [
                    'form-control',
                    sourceValue !== '' &&
                    isSourceValueValid ? 'is-valid' : isSourceValueValid === false ? 'is-invalid' : ''
                  ].join(' ')
                }
                value={sourceValue}
                onChange={(event) => handleSourceChange(event.target.value)}
                disabled={isLoading}
              />
              <div className="text-muted mt-2">
                {t('common.available')}:
                <button
                  className="btn btn-link tx-dark tx-bold p-0 ms-1"
                  onClick={() => handleSourceChange(sourceBalance + '', true)}
                  disabled={isLoading}
                >
                  <FormattedNumber
                    value={sourceBalance}
                    postfix={sourceCurrency}
                    floor={true}
                  />
                </button>
              </div>
            </div>
          </div>

          <FontAwesomeIcon icon={faArrowDownToLine} className="d-block my-1 tx-32 cur-pointer" onClick={handleSwap}/>

          <div className="wd-100p">
            <div className="card-title pb-0 h1 tx-bold">{targetCurrency}</div>
            <div className="card-body pt-0">
              <input
                type="number"
                className={
                  [
                    'form-control mb-2',
                    sourceValue !== '' &&
                    isSourceValueValid ? 'is-valid' : isSourceValueValid === false ? 'is-invalid' : ''
                  ].join(' ')
                }
                value={targetValue}
                onChange={(event) => handleTargetChange(event.target.value)}
                disabled={isLoading}
              />
              <div className="text-muted wd-100p tx-right mt-2">
                <div>
                  1 {sourceCurrency} =
                  <FormattedNumber
                    value={calculateTarget('1')}
                    postfix={targetCurrency}
                    decimals={isHnyTarget ? 2 : 6}
                    floor={isHnyTarget ? true : undefined}
                    className="ms-1"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column flex-md-row justify-content-between align-items-center wd-100p wd-lg-500 mb-3 wide-buttons px-4">
            <button
              className="btn btn-dark me-md-3 mb-2 mb-md-0 wd-100p wd-md-300 d-flex d-md-block justify-content-between align-items-center"
              onClick={handleSwap}
              disabled={isLoading}
            >
              <FontAwesomeIcon icon={faRightLeft} className="me-2 order-2 order-md-1"/>
              <span className="order-1 order-md-1">{t('common.swap')}</span>
            </button>

            <button
              className="btn btn-primary"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {t('common.exchange')}
              <IconWithLoading isLoading={isLoading}/>
            </button>
          </div>

          {!allowedAmount && (
            <div className="allowance-alert">
              <button
                className="btn btn-primary"
                onClick={handleAllowance}
                disabled={isLoading}
              >
                <IconWithLoading isLoading={isLoading}/>
                {t('common.allowance')}
              </button>
            </div>
          )}
        </div>
      </div>

      {(isPending) && <Preloader/>}
    </React.Suspense>
  )
}
