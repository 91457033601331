import React, {useState, useTransition} from "react";
import Preloader from "@components/common/Preloader";
import useCurrentPageTitle from "@hooks/useCurrentPageTitle";
import BeesShop from "@pages/Shop/components/BeesShop";
import SlotsShop from "@pages/Shop/components/SlotsShop";
import ItemsShop from "@pages/Shop/components/ItemsShop";
import {useRecoilValue} from "recoil";
import {isRegisteredSelector} from "@stores/beesStore";
import Register from "@pages/Register";
import {useTranslation} from "react-i18next";

enum MarketplaceTab {
  BEES,
  ITEMS,
  SLOTS,
}

export default function ShopPage() {
  const {t} = useTranslation();
  const [isPending] = useTransition();
  const [currentTab, setCurrentTab] = useState<MarketplaceTab>(MarketplaceTab.BEES);
  const pageTitle = useCurrentPageTitle();
  const isRegistered = useRecoilValue(isRegisteredSelector);

  const tabs = [
    {
      id: MarketplaceTab.BEES,
      name: t('shop.tabs.bees'),
      component: BeesShop,
    },
    {
      id: MarketplaceTab.ITEMS,
      name: t('shop.tabs.items'),
      component: ItemsShop,
    },
    {
      id: MarketplaceTab.SLOTS,
      name: t('shop.tabs.hectares'),
      component: SlotsShop,
    },
  ];

  return (
    <React.Suspense fallback={<Preloader/>}>
      <div className="container-fluid pb-0 ps-lg-0 pe-lg-4 ht-100p d-flex flex-column">
        <div className="d-flex d-md-none align-items-center mb-4">
          <h1 className="mb-0">{pageTitle}</h1>
        </div>

        {!isRegistered ? (<div><Register/></div>) : (
          <>
            <div className="mb-3">
              {tabs.map((tab) => (
                <button
                  key={`shop-tab-switch-${tab.id}`}
                  className={`btn ${tab.id === currentTab ? 'btn-primary' : 'btn-outline-dark'} me-3`}
                  onClick={() => setCurrentTab(tab.id)}
                >
                  {tab.name}
                </button>
              ))}
            </div>

            {tabs.map((tab) => {
              return tab.id === currentTab ? (<tab.component key={`active-shop-tab-${tab.id}`}/>) : null;
            })}
          </>
        )}
      </div>

      {(isPending) && <Preloader/>}
    </React.Suspense>
  )
}
