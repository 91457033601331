import {ReactComponent as apiaryIconSvg} from '@assets/images/icons/icon-apiary.svg';
import {ReactComponent as bankIconSvg} from '@assets/images/icons/icon-bank.svg';
import {ReactComponent as gameIconSvg} from '@assets/images/icons/icon-game.svg';
import {ReactComponent as giftIconSvg} from '@assets/images/icons/icon-gift.svg';
import {ReactComponent as groupIconSvg} from '@assets/images/icons/icon-group.svg';
import {ReactComponent as handshakeIconSvg} from '@assets/images/icons/icon-handshake.svg';
import {ReactComponent as honeyIconSvg} from '@assets/images/icons/icon-honey.svg';
import {ReactComponent as lottoIconSvg} from '@assets/images/icons/icon-lotto.svg';
import {ReactComponent as marketIconSvg} from '@assets/images/icons/icon-market.svg';
import {ReactComponent as moneyIconSvg} from '@assets/images/icons/icon-money.svg';
import {ReactComponent as nftIconSvg} from '@assets/images/icons/icon-nft.svg';
import {ReactComponent as securityIconSvg} from '@assets/images/icons/icon-security.svg';
import {ReactComponent as ticketsIconSvg} from '@assets/images/icons/icon-tickets.svg';
import {ReactComponent as walletIconSvg} from '@assets/images/icons/icon-wallet.svg';


export const ApiaryIcon = apiaryIconSvg;
export const BankIcon = bankIconSvg;
export const GameIcon = gameIconSvg;
export const GiftIcon = giftIconSvg;
export const GroupIcon = groupIconSvg;
export const HandshakeIcon = handshakeIconSvg;
export const HoneyIcon = honeyIconSvg;
export const LottoIcon = lottoIconSvg;
export const MarketIcon = marketIconSvg;
export const MoneyIcon = moneyIconSvg;
export const NftIcon = nftIconSvg;
export const SecurityIcon = securityIconSvg;
export const TicketsIcon = ticketsIconSvg;
export const WalletIcon = walletIconSvg;
