import React from "react";
import cardGray from "@assets/images/nfts/sets/gray.png";
import cardWhite from "@assets/images/nfts/sets/white.png";
import cardBlue from "@assets/images/nfts/sets/blue.png";
import cardPurple from "@assets/images/nfts/sets/purple.png";
import cardGold from "@assets/images/nfts/sets/gold.png";
import {ItemsSet, NFT} from "@stores/beesStore";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/pro-solid-svg-icons/faTimes";

interface GameCardProps {
  onClick?: () => void;
  item: NFT;
  itemSet: ItemsSet;
  beeName?: string;
  amount?: number;
}

export default function GameCard({onClick, item, itemSet, beeName, amount}: GameCardProps) {
  const {t} = useTranslation();
  let cardColor = 'gray';
  let cardImage = cardGray;

  switch (itemSet.setId) {
    case 1:
      cardColor = 'gray';
      cardImage = cardGray;
      break;
    case 2:
      cardColor = 'white';
      cardImage = cardWhite;
      break;
    case 3:
      cardColor = 'blue';
      cardImage = cardBlue;
      break;
    case 4:
      cardColor = 'purple';
      cardImage = cardPurple;
      break;
    case 5:
      cardColor = 'gold';
      cardImage = cardGold;
      break;
  }

  return (
    <div
      className={`my-2 game-card game-card-${cardColor}`}
      onClick={onClick}
    >
      <img src={cardImage} alt={item.name} title={item.name}/>
      <div className="game-card-set">{itemSet.name}</div>
      <div className="game-card-title">{item.name}</div>
      <div
        className="game-card-image"
      >
        <img
          src={item.image}
          alt={`${item.name}`}
        />
      </div>
      <div className="game-card-bee">
        <span>{t('common.bee')}:</span>
        {beeName}
      </div>
      <div className="game-card-profit">
        <span className="game-card-profit-title">{t('common.profit')}:</span>
        <span className="game-card-profit-value">+{item.bonusPercents}%</span>
      </div>
      {!!amount && (
        <div className="game-card-amount">
          <span>{t('common.amount')}: {amount}</span>
        </div>
      )}
      {!amount && onClick && (
        <button className="remove-item" onClick={onClick}>
          <FontAwesomeIcon icon={faTimes}/>
        </button>
      )}
    </div>
  );
}
